export const FilterType = {
  MONEY: `money_status`,
  PRINTED: `document_printed`,
  TURN_ID: `performance_turn_id`,
  PERFORMANCE_ID: `performance_id`,
  PERFORMANCE_CID: `performance_cid`,
  NOMINATION: `nomination_id`,
  AGE_CATEGORY: `nomination_age_category`,
  RATING: `performance_rating_sum`,
  PRIZE: `performance_prize`,
  PRIZE_MANUAL: `performance_prize_manual`,
  PHONOGRAM_STATUS: `performance_phonogram_status`,
  START_POINT: `start_with_point`,
  DURATION: `performance_duration`,
  CITY: `performance_city`,
  ARCHIVE: `archive`,
  START_TIME: `performance_start_time`,
  CREATE_TIME: `performance_create_time`,
  QUALIFICATION: `qualification_id`,
  PLAYER_COUNT: `performance_player_count`,
  TRANSACTION_TIME: `money_date`,
};

export const filterTypeToTitle = {
  [FilterType.MONEY]: `Статус оплаты`,
  [FilterType.PRINTED]: `Диплом`,
  [FilterType.TURN_ID]: `Порядковый номер<br>выступления`,
  [FilterType.PERFORMANCE_ID]: `Pегистрационный<br>номер в системе`,
  [FilterType.PERFORMANCE_CID]: `Pегистрационный<br>номер на мероприятии`,
  [FilterType.NOMINATION]: `Номинация`,
  [FilterType.AGE_CATEGORY]: `Возрастная категория`,
  [FilterType.RATING]: `Сумма баллов`,
  [FilterType.PRIZE]: `Занятое место`,
  [FilterType.PRIZE_MANUAL]: `Назначенное место`,
  [FilterType.PHONOGRAM_STATUS]: `Статус фонограммы`,
  [FilterType.START_POINT]: `Начало выступления`,
  [FilterType.DURATION]: `Длительность`,
  [FilterType.CITY]: `Город`,
  [FilterType.ARCHIVE]: `Aрхив`,

  [FilterType.START_TIME]: `Время и дата начала`,
  [FilterType.CREATE_TIME]: `Время и дата регистрации заявки`,
  [FilterType.QUALIFICATION]: `Квалификация`,
  [FilterType.PLAYER_COUNT]: `Число участников`,
  [FilterType.TRANSACTION_TIME]: `Время и дата оплаты`,
};

// проверить отправку selection
// формирование номинации
// отправка datetime

// export const DEFAULT_FILTER = {"archive": `0`};
export const DEFAULT_FILTER = {[FilterType.ARCHIVE]: {values: [`0`]}};


export const FilterMode = {
  BOOLEAN: `boolean`,
  MULTIPLE: `multiple`,
  SELECTION: `selection`,
  DATETIME: `datetime`,
};

export const ORDER_FILTER_TYPE = [
  FilterType.START_TIME,
  FilterType.CREATE_TIME,
  FilterType.TRANSACTION_TIME,
  FilterType.ARCHIVE,
  FilterType.MONEY,
  FilterType.PRINTED,
  FilterType.TURN_ID,
  FilterType.PERFORMANCE_ID,
  FilterType.PERFORMANCE_CID,
  FilterType.NOMINATION,
  FilterType.AGE_CATEGORY,
  FilterType.QUALIFICATION,
  FilterType.PLAYER_COUNT,
  FilterType.RATING,
  FilterType.PRIZE,
  FilterType.PRIZE_MANUAL,
  FilterType.PHONOGRAM_STATUS,
  // FilterType.START_POINT, // 14/03/2023 скрыто, ибо не работает
  FilterType.DURATION,
];
