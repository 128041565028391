import {NTR} from "../../name-to-request";

export default class OrganizationParsing {
  static organizationId(response) {
    return response[NTR.ORGANIZATION_ID];
  }

  static organizationTitle(data) {
    const organizationTitle = data.organization_title;

    if (!organizationTitle || organizationTitle === NTR.NO_ACCESS) {
      return ``;
    }

    return organizationTitle.replace(/\s+/g, ` `).trim();
  }

  static organizationAlias(data) {
    const alias = data.organization_alias;

    if (!alias || alias === NTR.NO_ACCESS) {
      return [];
    }

    const languages = Object.keys(alias);

    return languages.map((lang) => {
      return {
        language: lang,
        title: alias[lang],
      };
    });
  }

  static organizationCity(data) {
    const organizationCity = data.organization_city;

    if (!organizationCity || organizationCity === NTR.NO_ACCESS) {
      return ``;
    }

    return organizationCity;
  }

  static organizationCityPrint(data) { // +
    const organizationCityPrint = data.organization_city_print;

    return !!organizationCityPrint && organizationCityPrint !== NTR.NO_ACCESS && organizationCityPrint === NTR.ONE;
  }

  static organizationGoogleCity(data) {
    const organizationGoogleCity = data.organization_google_city;

    if (!organizationGoogleCity || organizationGoogleCity === NTR.NO_ACCESS) {
      return ``;
    }

    return organizationGoogleCity;
  }
}
