import DivComponent from "../../../component/base/div";
import TextInputComponent from "../../../component/input/text-input/text-input";
import {ContestParameter} from "../../../data/contest/parameters";
import {NTR} from "../../../name-to-request";
import {isEmptyObject} from "../../../utils/common";
import {getTitleRegistrationField} from "../../../utils/get-title-registration-field/get-title-registration-field";
import {RenderPosition, render, replace} from "../../../utils/render";

const DEFAULT_AGE_CATEGORY = {
  ageCategoryId: ``,
  title: ``,
};

export default class AgeCategoryController { // class AgeCategoryForPageRegistrationController
  constructor({container, api,ageCategory = Object.assign({}, DEFAULT_AGE_CATEGORY), ageCategories = [], nomination = {}, contestId, tabId, nameField, setting, languageController, onAgeCategoryChange}) {
    this._container = container;
    this._api = api;
    this._ageCategory = ageCategory;
    this._ageCategories = ageCategories;
    this._nominationId = isEmptyObject(nomination) ? `` : nomination.nominationId;
    this._contestId = contestId;
    this._tabId = tabId;
    this._nameField = nameField;
    this._setting = setting;
    this._languageController = languageController;
    this._title = this._getTitleValue();
    this._isRequired = setting.isRequired;
    this._placeholder = setting.placeholder;
    this._isValid = !this._isRequired;
    this._onAgeCategoryChange = onAgeCategoryChange;

    this._contentComponent = null;
    this._inputComponent = null;

    this._onCheckAgeCategoryHandler = this._onCheckAgeCategoryHandler.bind(this);

  }

  show() {
    this._contentComponent.show();
  }

  hide() {
    this._contentComponent.hide();
  }

  _getTitleValue() {
    return getTitleRegistrationField(this._languageController, `age_category`, this._setting.title);
  }

  get title() {
    return this._title;
  }

  get isValid() {
    return this._isValid;
  }

  prepare() {
    if (typeof this._setting.isSaveDataAfterRegistration === `undefined`) {
      return;
    }

    if (!this._setting.isSaveDataAfterRegistration) {
      this.clearData();
    }
  }

  render(container) {
    if (container) {
      this._container = container;
      this._contentComponent = null;
    }

    const oldContentComponent = this._contentComponent;
    this._contentComponent = new DivComponent(`form-registration__fieldAgeCategory`);

    this._renderAgeCategory();

    if (oldContentComponent) {
      replace(this._contentComponent, oldContentComponent);
    } else {
      render(this._container, this._contentComponent.getElement(), RenderPosition.BEFOREEND);
    }

    this._validate();
  }

  showValidate() {
    this._inputComponent.mode = `active`;
  }

  setNominationId(data) {
    this._nominationId = data;
    this._getAgeCategories();
  }

  setNominationIdNew(nomination) {
    this._nominationId = nomination.nominationId;
    this._getAgeCategories();
  }

  clearData() {
    this._setAgeCategory(DEFAULT_AGE_CATEGORY);
    this.render();

    this._onDataChange();
  }

  _getAgeCategories() {


    if (this._nominationId === ``) {
      this._setAgeCategories();
      return;
    }

    this._api.getContest({
      contestId: this._contestId,
      size: NTR.NULL,
      filter: {
        [NTR.NOMINATION_ID]: this._nominationId
      },
      parameters: [
        ContestParameter.AGE_CATEGORY,
      ],
    })
    .then((contest) => {
      // debugger;
      // this._setAgeCategories(contest.ageCategories);
      this._setAgeCategories(contest[0].ageCategories);
    });
  }

  _setAgeCategories(ageCategories = []) {

    this._ageCategories = ageCategories;

    const ageCategory = this._ageCategories.find((item) => item.title === this._ageCategory.title);

    if (ageCategory && ageCategory.ageCategoryId !== this._ageCategory.ageCategoryId) {
      this._setAgeCategory(ageCategory);
        
      this._onDataChange();
    }

    if (!ageCategory) {
      if (this._inputComponent) {
        this._inputComponent.clear();
      }
      this._setAgeCategory(DEFAULT_AGE_CATEGORY);
      this._onDataChange();
    }

    if (this._inputComponent) {
      this._inputComponent.dropDownItems = this._getAgeCategoryDropDownItems();
    }
  }

  _getAgeCategoryDropDownItems() {
    return this._ageCategories.map((ageCategory) => {
      return {
        id: ageCategory.ageCategoryId,
        text: ageCategory.title,
      };
    });
  }

  _renderAgeCategory() {

    this._inputComponent = new TextInputComponent({
      label: this._title,
      value: this._ageCategory.title,
      inputId: this._ageCategory.ageCategoryId,
      placeholder: this._placeholder,
      isReadOnly: true,
      isRequired: this._isRequired,
      dropDownItems: [],
      onDropDownItemCheck: this._onCheckAgeCategoryHandler,
    });
    this._getAgeCategories();

    render(this._contentComponent.getElement(), this._inputComponent.getElement(), RenderPosition.BEFOREEND);
  }

  _validate() {
    if (!this._isRequired) {
      return;
    }

    this._isValid = this._inputComponent.isValid;
  }

  _getAgeCategoryById(ageCategoryId) {
    return this._ageCategories.find((ageCategory) => ageCategory.ageCategoryId === ageCategoryId);
  }

  _setAgeCategory(data) {
    this._ageCategory = Object.assign({}, data);
  }

  _onDataChange() {
    this._validate();
    this._onAgeCategoryChange(this._ageCategory, this._tabId, this._nameField);
  }

  _onCheckAgeCategoryHandler(ageCategoryId) {
    const checkAgeCategory = this._getAgeCategoryById(ageCategoryId);
    if (!checkAgeCategory) {
    
      return;
    }

    this._setAgeCategory(checkAgeCategory);
    this._onDataChange();
  }
}
