import TextInputComponent from "../../component/input/text-input/text-input";
import {ButtonTitle, CITY_TEXT_BLOCKS, DEFAULT_CITY} from "../../const";
import Popup, {ContentType, PopupSize} from "../../static/popup/popup";
import {debounce} from "../../utils/debounce";
import {render, RenderPosition} from "../../utils/render";
import {renderGoogleCityPopup} from "../popup-custom/google-city";
import SearchController from "../search";


export default class CitySearchController extends SearchController {
  constructor({searchAPI, titleInputName, isRequired = false, placeholder = ``, setting = {}, onTitleCheckHandler}) {
    super();

    this._searchAPI = searchAPI;
    this._titleInputName = titleInputName;
    this._isRequired = isRequired;
    this._placeholder = placeholder;

    this._noFindedCityText = setting.noFindedCityText || CITY_TEXT_BLOCKS[0].text;
    this._googlePopupTitle = setting.googlePopupTitle || CITY_TEXT_BLOCKS[1].text;
    this._errorCity = setting.errorCity || CITY_TEXT_BLOCKS[2].text;

    this._onTitleCheckHandler = onTitleCheckHandler;
    this._isAvailableGoogleMaps = true;
    this._isValid = true;

    this._onChangeValue = () => {};

    this._city = {};
    this._cities = [];
    this._inputComponent = null;

    this._onCityCheckHandler = this._onCityCheckHandler.bind(this);
    this._onAddGoolgeCityTextButtonClickHandler = this._onAddGoolgeCityTextButtonClickHandler.bind(this);
    this._onChangeHandler = this._onChangeHandler.bind(this);
  }

  get isValid() {
    return this._isValid;
  }

  get errorValidateText() {
    return this._errorCity;
  }

  set isValid(data) {
    this._isValid = data;
  }

  showValidate() {}

  onChangeValue(handler) {
    this._onChangeValue = handler;
  }

  render(container, value, inputId) {
    this._city.city = value;
    this._city.cityId = inputId;
    this._renderInput(container);

    this.isValid = !!this._city.city;
  }

  _renderInput(container) {
    this._inputComponent = new TextInputComponent({
      label: this._titleInputName,
      value: this._city.city,
      inputId: ``,
      isRequired: this._isRequired,
      placeholder: this._placeholder,
      onDropDownItemCheck: this._onCityCheckHandler,
    });

    render(container, this._inputComponent.getElement(), RenderPosition.BEFOREEND);

    this._inputComponent.setInputHandler(debounce(this._onInputHandler));
    this._inputComponent.setChangeHandler(this._onChangeHandler);
  }

  _renderGoogleCityInput() {
    renderGoogleCityPopup(this._api, this._googlePopupTitle, this._inputComponent.value, this._onAddGoolgeCityTextButtonClickHandler);
  }

  _onAddGoolgeCityTextButtonClickHandler({google: googleCity, googleId}) {
    this._inputComponent.value = googleCity;

    this._city.city = googleCity;
    this._city.cityId = ``;
    this._city.googleId = googleId;
    this._city.isCityText = false;

    this.isValid = !!this._city.googleId;

    this._onChangeValue({name: `city`, value: this._city.city, inputId: this._city.googleId});
    this._onTitleCheckHandler(this._city);
  }

  _renderTextCityInput() {
    const input = new TextInputComponent({
      value: this._inputComponent.value,
    });

    Popup.showCustom({
      api: this._api,
      size: PopupSize.SMALL,
      title: `Укажите название населенного пункта`,
      contents: [
        {
          type: ContentType.FRAGMENT,
          content: input.getElement(),
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: ButtonTitle.CANCEL,
              isClose: true,
            },
            {
              title: ButtonTitle.SAVE,
              onClickHandler: () => this._onAddCityTextButtonClickHandler(input),
              isClose: true,
            }
          ],
        }
      ]
    });
  }

  _onAddCityTextButtonClickHandler(input) {
    const value = input.value;

    this._inputComponent.value = value;

    this._city.city = value;
    this._city.googleId = ``;
    this._city.isCityText = true;

    // this.isValid = !!this._city.googleId;


    this._onChangeValue({name: `city`, value: this._city.city, inputId: this._city.googleId});
    this._onTitleCheckHandler(this._city);
  }

  _onCityCheckHandler(googleId) {
    if (googleId === `addButton`) {
      this._addCityText();
      return;
    }

    const city = this._cities.find((it) => it.googleId === googleId);

    if (!city) {
      this._city = Object.assign({}, DEFAULT_CITY);
    } else {
      this._city = city;
    }

    this.isValid = !!this._city.googleId;

    this._onChangeValue({name: `city`, value: this._city.city, inputId: this._city.googleId});
    this._onTitleCheckHandler(this._city);
  }

  _onChangeHandler(value) {
    if (!value) {
      this._city = Object.assign({}, DEFAULT_CITY);
      this.isValid = !!this._city.googleId;

      this._onChangeValue({name: `city`, value: this._city.city, inputId: this._city.googleId});
      this._onTitleCheckHandler(this._city);
    }
  }

  _addCityText() {
    if (this._isAvailableGoogleMaps) {
      this._renderGoogleCityInput();
      return;
    }

    this._renderTextCityInput();
  }

  _getSearchData(text) {
    return this._searchAPI.getCity({
      text,
    });
  }

  _showSearched(cities, query) {
    if (!query) {
      this._cities = [];
      this._inputComponent.dropDownItems = [];
      return;
    }

    this._cities = cities;
    this._inputComponent.dropDownItems = this._getItems(cities);
  }

  _getItems(cities) {
    const dropDownItems = cities.map((city) => {
      return {
        id: city.googleId,
        text: city.city,
        isMarked: city.typeSearch === `related`,
      };
    });

    dropDownItems.push({
      id: `addButton`,
      text: this._noFindedCityText,
    });

    return dropDownItems;
  }
}
