import API from "./api";
import {LanguageToEngText} from "./const";
import LanguageLangAdapterController from "./controllers/lang-lango-adapter/language";
// import LanguageLangAdapterController from "./controllers/lang-lango-adapter/language";
// import LanguageLangController from "./controllers/lang-setting/language";
import RegistationPageLangController from "./controllers/lang-setting/registration-page";
import GlobalSettingLangoController from "./controllers/lango-setting/global-setting";
// import LanguageLangoController from "./controllers/lango-setting/language";
import PhonogramLoadController from "./controllers/load/phonogram";
import AgeCategoryController from "./controllers/page/registration-page/age-category";
import CityController from "./controllers/page/registration-page/city";
import ContactPeoplesController from "./controllers/page/registration-page/contact-peoples";
import {renderContestsList} from "./controllers/page/registration-page/contest-list";
import NominationController from "./controllers/page/registration-page/nomination";
import PerformanceController from "./controllers/page/registration-page/performance";
import QualificationController from "./controllers/page/registration-page/qualification";
import TextController from "./controllers/page/registration-page/text";
import ConventionPopupController from "./controllers/popup-custom/convention";
import SetDataRegistration from "./controllers/registration_new";
import PerformanceData from "./data/performance/performance";
import {getTitleButton} from "./utils/get-button-title/get-button-title";
import {getColorLabelRegistrationField} from "./utils/get-color-label-registration-field/get-color-label-registration-field";
import {getPatternRegistrationField} from "./utils/get-pattern-registration-field/get-pattern-registration-field";
import {getPopupRegistrationField} from "./utils/get-popup-registration-field/get-popup-registration-field";
import {getTabRegistration} from "./utils/get-tab-registration/get-tab-registration";
import {getTextBlocksRegistrationField, getTextBlocksRegistrationFieldWithComponentId} from "./utils/get-text-blocks/get-text-blocks";
import {getTitleRegistrationField, getTitleRegistrationFieldWithComponentId} from "./utils/get-title-registration-field/get-title-registration-field";

const api = new API();
window.constructorRegistrationFormTest = new RegistationPageLangController({api});


// const AccessLanguages = [
//   Language.ZH,
//   Language.KO,
//   Language.EN,
//   Language.RU,
// ];

window.globalSettingLangoController = new GlobalSettingLangoController({api});
const AccessLanguages = window.globalSettingLangoController.languages;

const languageLango = {};
AccessLanguages
.filter((it) => it.isShow)
.forEach((lang) => {
  languageLango[lang.lang] = new LanguageLangAdapterController({api, lang: lang.lang, language: LanguageToEngText[lang.lang]});
  // languageLango[lang.lang] = new LanguageLangController({api, language: LanguageToEngText[lang.lang]});
  // languageLango[lang.lang] = new LanguageLangController({api, language: LanguageToEngText[lang.lang]});
});


window.newLanguageLango = languageLango;


window.newRailConventionPopup = ConventionPopupController;
window.newGetTitleRegistrationField = getTitleRegistrationField;
window.newGetTitleRegistrationFieldWithComponentId = getTitleRegistrationFieldWithComponentId;
window.newGetPatternRegistrationField = getPatternRegistrationField;
window.newGetTextBlocksRegistrationField = getTextBlocksRegistrationField;
window.newGetTextBlocksRegistrationFieldWithComponentId = getTextBlocksRegistrationFieldWithComponentId;
window.newGetPopupRegistrationField = getPopupRegistrationField;
window.newGetTabRegistration = getTabRegistration;
window.newGetTitleButton = getTitleButton;
window.newGetColorLabelRegistrationField = getColorLabelRegistrationField;

window.newPerformanceData = new PerformanceData({api});
window.newSetDataRegistration = new SetDataRegistration({api});
window.newPhonogramLoadController = PhonogramLoadController;

window.newPerformanceController = PerformanceController;
window.newNominationController = NominationController;
window.newAgeCategoryController = AgeCategoryController;
window.newQualificationController = QualificationController;
window.newCityController = CityController;
window.newContactPeoplesController = ContactPeoplesController;

window.newTextController = TextController;

window.renderContestsList = renderContestsList;
