import {NTR} from "../../name-to-request";
import RequestController from "../../static/request/request";
import LinkModel from "./model";
import LinkParsing from "./parsing";
import LinkRequest from "./request";

export default class LinkData {
  constructor({api, isShowError = false}) {
    this._api = api;
    this._isShowError = isShowError;
  }

  getSoundmanAuthLink(soundmanId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getSoundman,
        parameter: {soundmanId},
        checkRequiredParameter: {soundmanId},
        requiredResponseParameters: [NTR.AUTH_LINK],
        parseResponse: LinkParsing.authLinkData,
        onResolveHandler: (authLink) => resolve(authLink),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку авторизации.`),
      }).send();
    });
  }

  getSoundmanAuthLink2(soundmanId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getSoundman,
        parameter: {soundmanId},
        checkRequiredParameter: {soundmanId},
        requiredResponseParameters: [NTR.AUTH_LINK],
        parseResponse: LinkParsing.authLinkData,
        // onResolveHandler: (authLink) => resolve(authLink),
        onResolveHandler: (authLink) => resolve(`${window.location.origin}${authLink}`),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку авторизации.`),
      }).send();
    });
  }

  getCompereAuthLink(compereId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getCompere,
        parameter: {compereId},
        checkRequiredParameter: {compereId},
        requiredResponseParameters: [NTR.AUTH_LINK],
        parseResponse: LinkParsing.authLinkData,
        onResolveHandler: (authLink) => resolve(authLink),
        // onResolveHandler: (authLink) => resolve(`${window.location.origin}${authLink}`),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку авторизации.`),
      }).send();
    });
  }

  getCompereAuthLink2(compereId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getCompere,
        parameter: {compereId},
        checkRequiredParameter: {compereId},
        requiredResponseParameters: [NTR.AUTH_LINK],
        parseResponse: LinkParsing.authLinkData,
        // onResolveHandler: (authLink) => resolve(authLink),
        onResolveHandler: (authLink) => resolve(`${window.location.origin}${authLink}`),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку авторизации.`),
      }).send();
    });
  }

  getRefereeAuthLink(refereeId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getReferee,
        parameter: {refereeId},
        checkRequiredParameter: {refereeId},
        requiredResponseParameters: [NTR.AUTH_LINK],
        parseResponse: LinkParsing.authLinkData,
        onResolveHandler: (authLink) => resolve(authLink),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку авторизации.`),
      }).send();
    });
  }

  getRefereeAuthLink2(refereeId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getReferee,
        parameter: {refereeId},
        checkRequiredParameter: {refereeId},
        requiredResponseParameters: [NTR.AUTH_LINK],
        parseResponse: LinkParsing.authLinkData,
        onResolveHandler: (authLink) => resolve(`${window.location.origin}${authLink}`),
        // onResolveHandler: (authLink) => resolve(authLink),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку авторизации.`),
      }).send();
    });
  }

  getTerminalAuthLink(terminalId, onResolveHandler = () => {}) {
    new RequestController({
      api: this._api,
      getRequest: LinkRequest.getTerminal,
      parameter: {terminalId},
      checkRequiredParameter: {terminalId},
      requiredResponseParameters: [NTR.AUTH_LINK],
      parseResponse: LinkParsing.authLinkData,
      onResolveHandler: (authLink) => onResolveHandler(authLink),
      onRejectHandler: () => {},
      onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку авторизации.`),
    }).send();
  }

  getTerminalAuthLink2(terminalId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getTerminal,
        parameter: {terminalId},
        checkRequiredParameter: {terminalId},
        requiredResponseParameters: [NTR.AUTH_LINK],
        parseResponse: LinkParsing.authLinkData,
        onResolveHandler: (authLink) => resolve(`${window.location.origin}${authLink}`),
        // onResolveHandler: (authLink) => resolve(authLink),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку авторизации.`),
      }).send();
    });
  }

  getShortLink(link, onResolveHandler) {
    return new RequestController({
      api: this._api,
      getRequest: LinkRequest.getShort,
      parameter: {link},
      checkRequiredParameter: {link},
      requiredResponseParameters: [NTR.SHORT_LINK],
      parseResponse: LinkParsing.parseShortLinkData,
      isShowError: this._isShowError,
      onResolveHandler: (shortLink) => onResolveHandler(shortLink),
      onRejectHandler: () => {},
      onRequestErrorHandler: () => {},
    }).send();
  }

  getShortLinkNew(link) { // всех getShortLink перевести на новый
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getShort,
        parameter: {link},
        checkRequiredParameter: {link},
        requiredResponseParameters: [NTR.SHORT_LINK],
        parseResponse: LinkParsing.parseShortLinkData,
        isShowError: this._isShowError,
        onResolveHandler: (shortLink) => resolve(shortLink),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: () => {},
      }).send();
    });
  }

  getPaymentLink({performanceId, cost, payer, reccurent, currentPage, paymentRole, isShortLink}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getPaymentLink,
        parameter: {performanceId, cost, payer, reccurent, currentPage, paymentRole, isShortLink},
        checkRequiredParameter: {performanceId, cost, currentPage, paymentRole},
        requiredResponseParameters: [NTR.PAYMENT_LINK],
        parseResponse: (data) => LinkModel.parse(data),
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data.link),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить ссылку.`),
      }).send();
    });
  }

  searchCompany({performanceId, cost, payer, reccurent, currentPage, paymentRole, isShortLink}) {
    const getTextError = ({message}) => {
      if (message === `INN not found`) {
        return `По данному ИНН фирма не найдена.`;
      }

      return `Не удалось найти фирму.`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: LinkRequest.getPaymentLink,
        parameter: {performanceId, cost, payer, reccurent, currentPage, paymentRole, isShortLink},
        checkRequiredParameter: {performanceId, cost, payer, currentPage, paymentRole, isShortLink},
        requiredResponseParameters: [NTR.PAYER_TITLE],
        // parseResponse: (data) => {
        //   debugger;
        //   return LinkModel.parse(data);
        // },
        parseResponse: LinkModel.parse,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).send();
    });
  }
}

