import {checkAccess} from "../../utils/common";
import LinkParsing from "./parsing";

export default class LinkModel {
  constructor(data) {
    this.link = LinkParsing.paymentLink(data);
    this.paymentSystem = LinkParsing.paymentSystem(data);
    this.company = LinkParsing.payerTitle(data);
    this.peopleId = LinkParsing.peopleId(data);
    this.publicKey = LinkParsing.publicKey(data);

    this.access = LinkModel.getAccess(data);
  }

  toRAW() {
  }

  static newLink(data) {
    return new LinkModel(data);
  }

  static parse(data) {
    return LinkModel.newLink(data);
  }

  static getAccess(data) {
    return {
      link: checkAccess(data.payment_link),
      paymentSystem: checkAccess(data.payment_system),
      company: checkAccess(data.payer_title),
      peopleId: checkAccess(data.people_id),
      publicKey: checkAccess(data.public_key),
    };
  }
}
