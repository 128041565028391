import {checkAccess} from "../../utils/common";
import OrganizationParsing from "./parsing";

export default class OrganizationModel {
  constructor(id, data) {
    this.organizationId = id;

    this.title = OrganizationParsing.organizationTitle(data);
    this.city = OrganizationParsing.organizationCity(data);
    this.cityId = OrganizationParsing.organizationGoogleCity(data);
    this.isCityPrint = false;
    this.isRepresent = false;
    this.aliases = OrganizationParsing.organizationAlias(data);

    this.access = OrganizationModel.getAccess(data);
  }

  static parseOrganization(id, data) {
    return new OrganizationModel(id, data[id]);
  }

  static parseOrganizations(data) {
    const organizationIds = Object.keys(data);
    return organizationIds.map((organizationId) => OrganizationModel.parseOrganization(organizationId, data));
  }

  static getAccess(data) {
    return {
      title: checkAccess(data.organization_title),
      city: checkAccess(data.organization_city),
      cityId: checkAccess(data.organization_google_city),
      aliases: checkAccess(data.organization_alias),
    };
  }
}
