import Popup from "../static/popup/popup";
import {isEmptyObject} from "../utils/common";
import LangController from "./lang";

export default class LangSettingNewController {
  constructor() {
    this._onLoadedFile = this._onLoadedFile.bind(this);
  }

  getData(name) {
    return this.get(name)
    || this.getOld(name)
    || this.getLango(name)
    || this.getLangoOld(name);
  }

  getDataNew(name) {
    return this.get(name)
    || this.getOld(name);
  }

  getOld(name) {
    if (!this._settingOld) {
      this._settingOld = {};
    }

    const value = this._settingOld[name];
    return typeof value !== `undefined` ? value : null;
  }

  get(name) {
    if (!this._setting) {
      this._setting = {};
    }

    const value = this._setting[name];
    return typeof value !== `undefined` ? value : null;
  }

  getLangoOld(name) {
    if (!this._settingLangoOld) {
      this._settingLangoOld = {};
    }

    const value = this._settingLangoOld[name];
    return typeof value !== `undefined` ? value : null;
  }

  getLango(name) {
    if (!this._settingLango) {
      this._settingLango = {};
    }

    const value = this._settingLango[name];
    return typeof value !== `undefined` ? value : null;
  }

  set(name, value) {
    if (!this._setting) {
      this._setting = {};
    }
    this._setting[name] = value;
  }

  remove(name) {
    delete this._setting[name];
  }

  save() {
    LangController.setPropertyLang(this._nameSetting, this._setting);
    LangController.save(this._api, this._container, this._onLoadedFile);
  }

  saveNewLang(onResolveHandler = () => {}) {
    if (this._settingOld && !isEmptyObject(this._settingOld)) {
      this._setting = Object.assign({}, this._settingOld, this._setting);
    }

    LangController.saveNewLang(this._api, this._container, this._nameSetting, this._nameLang, this._setting, () => {
      if (this._settingOld && !isEmptyObject(this._settingOld)) {
        this._settingOld = {};

        LangController.setPropertyLang(this._nameSettingOld, this._settingOld);
        LangController.save(this._api, this._container, () => {});
      }

      onResolveHandler();
    });
  }

  removeNewLang(onResolveHandler) {
    LangController.removeNewLang(this._api, this._container, this._nameLang, onResolveHandler);
  }

  _onLoadedFile() {
    Popup.showResolveChangeNotification({text: `Сохранено!`});
  }
}
