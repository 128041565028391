export const setDurationForRegistration = ({performanceId = ``, duration = 0}, performanceAPI) => {
  if (!performanceId) {
    // return Promise.reject(`error`);
    // отправка ошибки
  }
  if (!duration) {
    return Promise.resolve(`skip`);
  }

  return performanceAPI.setPerformanceDuration(performanceId, duration)
  .catch(() => {});
};
