import {AUTHORIZATION_FORM_FIELD, ButtonTitle} from "../../const";
import Popup, {ContentType, PopupSize} from "../../static/popup/popup";
import {getChangedQuotText2} from "../../utils/common";
import CommonLangController from "../lang-setting/default";
import PatternController from "../pattern";

export default class ConventionPopupController {
  constructor({setting = Object.assign({}, AUTHORIZATION_FORM_FIELD.setting.popupConvention), contest = {}, onConventionButtonClickHandler = () => {}}) {
    this._setting = setting;
    this._contest = contest;
    this._privacyPolicyLink = new CommonLangController().privacyPolicyLink;
    this._termsOfUseLink = new CommonLangController().termsOfUseLink;
    this._onConventionButtonClickHandler = onConventionButtonClickHandler;
  }

  render() {
    Popup.showCustom({
      size: PopupSize.MEDIUM,
      title: this._setting.title || ``,
      contents: [
        {
          type: ContentType.TEXT,
          content: this._getSettingText(),
        },
        {
          type: ContentType.TEXT,
          content: this._getTermsOfUseText(),
        },
        {
          type: ContentType.TEXT,
          content: this._getPrivatyPolicyText(),
        },
        {
          type: ContentType.BUTTONS,
          content: [
            {
              title: ButtonTitle.CONTINUE,
              onClickHandler: this._onConventionButtonClickHandler,
              isClose: true,
            },
          ],
        }
      ]
    });
  }

  _getSettingText() {
    const text = getChangedQuotText2(this._setting.text) || ``;
    this._contest.privacyPolicy = this._privacyPolicyLink || ``;

    return PatternController.getText({
      pattern: text,
      data: this._contest,
    });
  }

  _getTermsOfUseText() {
    const link = this._termsOfUseLink;

    if (!link) {
      return ``;
    }

    return `– принимаю <a href="${link}" target="_blank">Лицензионное соглашение сайта PrimeTime.today</a>,`;
  }

  _getPrivatyPolicyText() {
    const link = this._privacyPolicyLink;

    if (!link) {
      return ``;
    }

    return `– согласен с <a href="${link}" target="_blank">Политикой в отношении обработки персональных данных</a>.`;
  }
}
