import {CostTariff} from "./tariff";

export const ContestTariff = {
  RATE_75: `rate_75`,
  RATE_99: `rate_99`,
  RATE_100: `rate_100`,
  RATE_129: `rate_129`,
  RATE_NONE: `rate_0`,
  RATE_110: `rate_110`,
  RATE_150: `rate_150`,
};

export const ContestTariffToRus = {
  [ContestTariff.RATE_75]: `75 руб.`,
  [ContestTariff.RATE_99]: `99 руб.`,
  [ContestTariff.RATE_100]: `100 руб.`,
  [ContestTariff.RATE_129]: `129 руб.`,
  [ContestTariff.RATE_110]: `110 руб.`,
  [ContestTariff.RATE_150]: `150 руб.`,
};

export const ContestTariffTable = {
  [CostTariff.RATE_75]: ContestTariff.RATE_75,
  [CostTariff.RATE_99]: ContestTariff.RATE_99,
  [CostTariff.RATE_100]: ContestTariff.RATE_100,
  [CostTariff.RATE_129]: ContestTariff.RATE_129,
  [CostTariff.RATE_110]: ContestTariff.RATE_110,
  [CostTariff.RATE_150]: ContestTariff.RATE_150,
};

export const ContestTariffTable2 = {
  [ContestTariff.RATE_75]: CostTariff.RATE_75,
  [ContestTariff.RATE_99]: CostTariff.RATE_99,
  [ContestTariff.RATE_100]: CostTariff.RATE_100,
  [ContestTariff.RATE_129]: CostTariff.RATE_129,
  [ContestTariff.RATE_110]: CostTariff.RATE_110,
  [ContestTariff.RATE_150]: CostTariff.RATE_150,
};
