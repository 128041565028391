import {EmailTemplate} from "../../const";
import Popup from "../../static/popup/popup";
import RequestController from "../../static/request/request";
import EmailTemplateRequest from "./request";

const ErrorRequestCode = {
  CODE_451: `451`,
  CODE_501: `501`,
  CODE_535: `535`,
  CODE_550: `550`,
  CODE_552: `552`,
  CODE_553: `553`,
  CODE_554: `554`,
  CODE_555: `555`,
};

const textErrorRequest = {
  [ErrorRequestCode.CODE_451]: `<b>Ошибка 451: За 1 час было отправлено слишком много писем. Отправка писем блокирована.</b><br>Подождите не менее часа и повторите попытку отправки.`,
  [ErrorRequestCode.CODE_501]: `<b>Ошибка 501: Не правильный адрес получателя.</b><br>Убедитесь в правильности указанного адреса и повторите попытку.`,
  [ErrorRequestCode.CODE_535]: `<b>Ошибка 535: Не правильный логин/пароль ОТПРАВИТЕЛЯ, аутентификация отклонена сервером.</b>`,
  [ErrorRequestCode.CODE_550]: `<b>Ошибка 550: Почтовый ящик ПОЛУЧАТЕЛЯ не существует.</b><br>Убедитесь в правильности указанного адреса и повторите попытку.`,
  [ErrorRequestCode.CODE_552]: `<b>Ошибка 552: Почтовый ящик ПОЛУЧАТЕЛЯ переполнен. Получатель должен освободить место в почтовом ящик.</b>`,
  [ErrorRequestCode.CODE_553]: `<b>Ошибка 553: Неверный адрес электронной почты получателя.</b><br>Убедитесь в правильности указанного адреса и повторите попытку.`,
  [ErrorRequestCode.CODE_554]: `<b>Ошибка 554: Ваш почтовый сервер заблокировал отправку письма. Скорее всего Ваши действия похожи на рассылку спама.</b><br>За подробностями можете обраться в техническую поддержку.`,
  [ErrorRequestCode.CODE_555]: `<b>Ошибка 555: Синтаксическая ошибка почты (например, пробел в имени почты).</b><br>Убедитесь в правильности указанного адреса и повторите попытку.`,
};

const isErrorRequestCode = (error) => {
  return Object.keys(ErrorRequestCode).some((code) => ErrorRequestCode[code] === error);
};

const getTextError = (error, defaultText) => {
  if (isErrorRequestCode(error.message)) {
    return textErrorRequest[error.message];
  }

  if (error.message === `mail settings not found`) {
    return `Для данного мероприятия не заданы настройки почты. Пожалуйста, перейдите на страницу настроек в раздел <a href="/contest_settings#mail_server">«Исходящая почта»</a>, и внесите логин и пароль приложения.`;
  }

  return defaultText;
};

export default class EmailTemplateData {
  constructor({api, isShowError = true}) {
    this._api = api;
    this._isShowError = isShowError;
  }

  sendPass(email) {
    const onErrorHandler = (error) => {
      if (error.message === `email not found`) {
        Popup.showError({text: `Электронная почта <b>${email}</b> не найдена.`});
        return;
      }

      if (error.message === `invalid password recovery email`) {
        Popup.showError({text: `Для восстановления пароля указан неверный адрес электронной почты: <b>${email}</b>.`});
        return;
      }

      Popup.showError({text: `Не удалось восстановить пароль.`});
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: EmailTemplateRequest.sendPassword,
        parameter: {email},
        checkRequiredParameter: {email},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => onErrorHandler(error),
      }).send();
    });
  }

  sendVirtualPayment({mailId, message}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: EmailTemplateRequest.sendMailId,
        parameter: {template: EmailTemplate.MONEY_TRANSFER, mailId, message},
        checkRequiredParameter: {mailId},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error, `Не удалось отправить запрос на списание средств.`), false),
      }).send();
    });
  }

  sendCustom({template, peopleId, performanceId, message}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: EmailTemplateRequest.sendCustom,
        parameter: {template, peopleId, performanceId, message},
        checkRequiredParameter: {template, peopleId, performanceId},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error, `Не удалось отправить письмо.`), false),
        // onRequestErrorHandler: (error) => error.showCustom(getTextError(error, `Не удалось отправить письмо.`)),
      }).send();
    });
  }

  sendCustomNew({templateId, peopleId, performanceId, message}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: EmailTemplateRequest.sendCustomNew,
        parameter: {templateId, peopleId, performanceId, message},
        checkRequiredParameter: {templateId, peopleId, performanceId},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error, `Не удалось отправить письмо.`), false),
      }).send();
    });
  }

  sendCustom2({template, mailId, performanceId, message}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: EmailTemplateRequest.sendCustom2,
        parameter: {template, mailId, performanceId, message},
        checkRequiredParameter: {template, mailId, performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        // onRejectHandler: () => {},
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => {
          if (this._isShowError) {
            error.showCustom(getTextError(error, `Не удалось отправить письмо.`), false);
          }
        },
      }).send();
    });
  }

  sendCustomNew2({templateId, mailId, performanceId, message}) {
    // template_id 210 not found
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: EmailTemplateRequest.sendCustomNew2,
        parameter: {templateId, mailId, performanceId, message},
        checkRequiredParameter: {templateId, mailId, performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        // onRejectHandler: () => {},
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => {
          if (this._isShowError) {
            error.showCustom(getTextError(error, `Не удалось отправить письмо.`), false);
          }
        },
      }).send();
    });
  }
}


