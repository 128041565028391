import {NTR} from "../../name-to-request";
import {getCodeCheck} from "../../utils/common";

export default class LinkRequest {
  static getSoundman({soundmanId}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.AUTH_LINK]: {
          [NTR.SOUNDMAN_ID]: soundmanId,
        },
      }
    };
  }

  static getCompere({compereId}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.AUTH_LINK]: {
          [NTR.COMPERE_ID]: compereId,
        },
      }
    };
  }

  static getReferee({refereeId}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.AUTH_LINK]: {
          [NTR.REFEREE_ID]: refereeId,
        },
      }
    };
  }

  static getTerminal({terminalId}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.AUTH_LINK]: {
          [NTR.TERMINAL_ID]: terminalId,
        },
      }
    };
  }

  static getShort({link}) {
    return {
      [NTR.SET_SHORT_LINK]: {
        [NTR.LINK]: link,
      }
    };
  }

  static getPaymentLink({performanceId, cost, payer, reccurent, currentPage, paymentRole, isShortLink = false}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.PAYMENT_LINK]: {
          [NTR.PERFORMANCE_ID]: performanceId,
          [NTR.AMOUNT_TO_PAY]: cost,
          [NTR.PAYER]: payer,
          [NTR.RECCURENT]: reccurent,
          [NTR.CURRENT_PAGE]: currentPage,
          [NTR.PAYMENT_ROLE]: paymentRole,
          [NTR.SHORT_LINK]: getCodeCheck(isShortLink),
          [NTR.CONSTANT]: isShortLink ? NTR.ONE : undefined,
        }
      }
    };
  }
}

