export default class FieldRegistrationController {
  constructor() {
    this._isShow = true;
  }


  get title() {
    return this._title;
  }

  get isValid() {
    return this._isValid;
  }

  get isRequired() {
    return this._isRequired;
  }

  get isShow() {
    return this._isShow;
  }

  set isShow(data) {
    this._isShow = data;
  }

  show() {
    this._contentComponent.show();
    this.isShow = true;
  }

  hide() {
    this._contentComponent.hide();
    this.isShow = false;
  }

  showValidate() {
    this._inputComponent.mode = `active`;
  }

  // clearData() {
  //   this._performance = ``;
  //   this._inputComponent.value = ``;
  //   this._inputComponent.validate();

  //   this._onDataChange();
  // }

  // focusInput() {
  //   this._inputComponent.focusInput();
  // }

  // _validate() {
  //   if (!this._isRequired) {
  //     return;
  //   }

  //   this._isValid = this._inputComponent.isValid;
  // }


}
