import {checkAccess} from '../../utils/common';
import TeamParsing from './parsing';


export default class TeamModel {
  constructor(id, data) {
    this.teamId = id;

    this.title = TeamParsing.teamTitle(data);
    this.city = TeamParsing.teamCity(data);
    this.cityId = TeamParsing.teamGoogleCity(data);
    this.isCityPrint = false;
    this.isRepresent = false;
    this.aliases = TeamParsing.teamAlias(data);

    this.access = TeamModel.getAccess(data);
  }

  static parseTeam(id, data) {
    return new TeamModel(id, data[id]);
  }

  static parseTeams(data) {
    const teamIds = Object.keys(data);
    return teamIds.map((teamId) => TeamModel.parseTeam(teamId, data));
  }

  static getAccess(data) {
    return {
      title: checkAccess(data.team_title),
      city: checkAccess(data.team_city),
      cityId: checkAccess(data.team_google_city),
      aliases: checkAccess(data.team_alias),
    };
  }
}


