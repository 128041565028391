import {UserFieldType} from "../../const";
import {isEmptyArray} from "../../utils/common";

export const getUserFieldDataPattern = (data, linkUserFields = []) => {
  const userField = data.userField;
  if (!userField) {
    return {};
  }

  const userFieldPattern = {};

  const isHasFiles = !!data.files;

  Object.keys(data.userField).forEach((userFieldId) => {
    if (linkUserFields.some((field) => field.userFieldId === userFieldId)) {
      if (isHasFiles) {
        // взять из массив файлов выступления значение ссылки
        const userFieldLinkIds = data.userField[userFieldId];

        if (userFieldLinkIds && !isEmptyArray(userFieldLinkIds)) {
          // найти файл-ссылку в массиве всех файлов
          userFieldLinkIds.forEach((linkId) => {
            const link = data.files
              // .filter((file) => file.type === FileType.IMAGE)
              // .find((item) => item.fileId === linkId);
              .filter((file) => file.type === UserFieldType.LINK)
              .find((item) => item.linkId === linkId);

            if (link) {
              userFieldPattern[`[[field_value_${userFieldId}]]`] = link.link || ``;
              userFieldPattern[`[[user_field_${userFieldId}]]`] = link.link || ``;
            }
          });
        }
      }
    } else {
      const value = data.userField[userFieldId];
      const value2 = value ? value.join(`, `) : ``;

      userFieldPattern[`[[field_value_${userFieldId}]]`] = value2.replace(/&/g, `$amp;`);
      userFieldPattern[`[[user_field_${userFieldId}]]`] = value2.replace(/&/g, `$amp;`);
    }
  });

  return userFieldPattern;
};
