export const getTextName = (people) => {
  const {surname, lastname, middlename} = people;

  const names = [];

  if (surname !== ``) {
    names.push(surname);
  }
  if (lastname !== ``) {
    names.push(lastname);
  }
  if (middlename !== ``) {
    names.push(middlename);
  }

  return names.join(` `);
};

export const getShortTextName = (people) => {
  const {surname, lastname} = people;

  const names = [];

  if (surname !== ``) {
    names.push(surname);
  }
  if (lastname !== ``) {
    names.push(lastname);
  }

  return names.join(` `);
};

export const getTextNameWithLetter = (people) => {
  const {surname, lastname, middlename} = people;

  const names = [];

  if (surname !== ``) {
    names.push(surname);
  }
  if (lastname !== ``) {
    names.push(lastname);
  }
  if (middlename !== ``) {
    names.push(middlename);
  }

  // Л<span>юбовь </span>
  return names.map((name) => {
    return `${name[0]}<span>${name.slice(1)} </span>`;
  }).join(``);

  // return names.join(` `);

};


export const isEqualityName = (people1, people2) => {
  return (people1.surname === people2.surname
    && people1.lastname === people2.lastname
    && people1.middlename === people2.middlename);
};
