import {RegistrationField} from "../../const";

export const getColorLabelRegistrationField = (colorMap, fieldName, componentId = `000000`) => {
  if (!colorMap) {
    return ``;
  }

  if (fieldName !== RegistrationField.PERFORMANCE
    && fieldName !== RegistrationField.PLAYERS
    && fieldName !== RegistrationField.PHONOGRAMS
    && fieldName !== RegistrationField.DURATION
    && fieldName !== RegistrationField.START_POINT) {
    return ``;
  }

  if (!componentId) {
    componentId = `000000`;
  }

  return componentId && colorMap[componentId] ? colorMap[componentId] : ``;
};
