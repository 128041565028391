import LangController from "../lang";
import LangSettingNewController from "../lang-setting_new";


export default class MailTemplateLangController extends LangSettingNewController {
  constructor({api}) {
    super();

    this._api = api;

    this._container = document.querySelector(`body`);
    this._settingOld = {};

    this._nameSetting = `settingMailTemplate`;
    this._nameLang = `lang_mail_template`;
    this._setting = LangController.getSettingNewLang(window.settingMailTemplate);
  }

  save() {
    this.saveNewLang();
  }

  get playerRegistration() {
    return this.get(`registrationMailTemplateId`) || ``;
  }

  get organizationRegistration() {
    return this.get(`registrationVerificationMailTemplateId`) || ``;
  }

  get playerPay() {
    return this.get(`payMailTemplateId`) || ``;
  }

  set playerRegistration(data) {
    this.set(`registrationMailTemplateId`, data);
  }

  set organizationRegistration(data) {
    this.set(`registrationVerificationMailTemplateId`, data);
  }

  set playerPay(data) {
    this.set(`payMailTemplateId`, data);
  }

  // // ниже проверить, заменить и удалить

  get registrationMailTemplateId() {
    return this.get(`registrationMailTemplateId`) || ``;
  }

  get registrationVerificationMailTemplateId() {
    return this.get(`registrationVerificationMailTemplateId`) || ``;
  }

  get payMailTemplateId() {
    return this.get(`payMailTemplateId`) || ``;
  }

  set registrationMailTemplateId(data) {
    this.set(`registrationMailTemplateId`, data);
  }

  set registrationVerificationMailTemplateId(data) {
    this.set(`registrationVerificationMailTemplateId`, data);
  }

  set payMailTemplateId(data) {
    this.set(`payMailTemplateId`, data);
  }
}

