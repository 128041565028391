import LangController from "../lang";
import LangSettingNewController from "../lang-setting_new";


export default class GlobalSettingLangoController extends LangSettingNewController {
  constructor({api}) {
    super();

    this._api = api;

    this._container = document.querySelector(`body`);

    this._nameSetting = `globalSetting`;
    this._nameSettingOld = `globalSetting`;
    this._settingOld = LangController.getPropertyLango(this._nameSettingOld);

    this._setting = LangController.getSettingNewLang(window[this._nameSetting]);
    this._nameLang = `lango_common`;
  }

  save() {
    this.saveNewLang();
  }

  getDataLang(name) {
    return this[`${name}Lang`];
  }

  get verificationMail() {
    return this.getDataNew(`verificationMail`) || {mail: ``, mailId: ``};
  }

  get languages() {
    return this.getDataNew(`languages`) || [{lang: `ru`, isShow: `true`}];
  }

  get headerColor() {
    return this.getDataNew(`HEADER_COLOR`) || `#ffffff`;
  }

  get logoColor() {
    return this.getDataNew(`LOGO_COLOR`) || `#ffffff`;
  }

  get textHeaderColor() {
    return this.getDataNew(`TEXT_HEADER_COLOR`) || `#51ddd4`;
  }

  get mainColor() {
    return this.getDataNew(`MAIN_COLOR`) || `#51ddd4`;
  }


  get headerColorLang() {
    return this.getDataNew(`HEADER_COLOR`);
  }

  get logoColorLang() {
    return this.getDataNew(`LOGO_COLOR`);
  }

  get textHeaderColorLang() {
    return this.getDataNew(`TEXT_HEADER_COLOR`);
  }

  get mainColorLang() {
    return this.getDataNew(`MAIN_COLOR`);
  }

  get logoLang() {
    return this.getDataNew(`LOGO`);
  }

  // get isShowRegistrationNavItemLang() {
  //   return this.get(`isShowRegistrationNavItem`);
  //   // const isShowRegistrationNavItem = this.get(`isShowRegistrationNavItem`);
  //   // return isShowRegistrationNavItem !== null ? isShowRegistrationNavItem : true;
  // }


  set verificationMail(data) {
    this.set(`verificationMail`, data);
  }

  set languages(data) {
    this.set(`languages`, data);
  }

  set headerColor(data) {
    this.set(`HEADER_COLOR`, data);
  }

  set logoColor(data) {
    this.set(`LOGO_COLOR`, data);
  }

  set textHeaderColor(data) {
    this.set(`TEXT_HEADER_COLOR`, data);
  }

  set mainColor(data) {
    this.set(`MAIN_COLOR`, data);
  }
}

