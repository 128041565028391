import SearchParsing from "./parsing";

export default class SearchModel {
  constructor(data) {
    this.performances = SearchParsing.performance(data);

    this.peoples = SearchParsing.people(data);
    this.teams = SearchParsing.team(data);
    this.organizations = SearchParsing.organization(data);
    this.cities = SearchParsing.city(data);
  }

  static parse(data) {
    return new SearchModel(data);
  }
}
