import {EQUALITY_REGEXP, LENGTH_STRING_REGEXP, LESS_GREATER_THAN_REGEXP, PATTERN_REGEXP} from "../../const/pattern";
import {getIsEqualityCondition} from "../pattern-equality/pattern-equality";
import {getIsLessGreatherThanCondition} from "../pattern-greather-less/pattern-greather-less";
import {getIsIncludes} from "../pattern-includes/pattern-includes";
import {getIsLengthStringCondition} from "../pattern-length-string/pattern-length-string";

// переделать как в форме регистрации
export const getIsConditionResult = (condition, variables, data, variableText) => {
  if (LENGTH_STRING_REGEXP.test(condition)) {
    const text = variableText.replace(PATTERN_REGEXP, (s) => data[s] || ``);
    return getIsLengthStringCondition(condition, text);
  }

  const variableValues = variables.map((variable) => data[variable] || ``);

  if (EQUALITY_REGEXP.test(condition)) {
    return getIsEqualityCondition(condition, variableValues);
  }

  if (LESS_GREATER_THAN_REGEXP.test(condition)) {
    return getIsLessGreatherThanCondition(condition, variableValues);
  }

  return getIsIncludes(condition, variableValues);
};
