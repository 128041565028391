import RequestController from "../../static/request/request";
import PhonogramRequest from "./request";

export default class PhonogramAPI {
  constructor({api, isShowError = true}) {
    this._api = api;
    this._isShowError = isShowError;
  }

  setTitle(phonogramId, title) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PhonogramRequest.setTitle,
        parameter: {phonogramId, title},
        checkRequiredParameter: {phonogramId, title},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось сохранить название фонограмме.`),
      }).send();
    });
  }

  setStartWithPoint(phonogramId, startPointId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PhonogramRequest.setStartWithPoint,
        parameter: {phonogramId, startPointId},
        checkRequiredParameter: {phonogramId, startPointId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить начало выступления.`),
      }).send();
    });
  }

  setStatus(phonogramId, statusId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PhonogramRequest.setStatus,
        parameter: {phonogramId, statusId},
        checkRequiredParameter: {phonogramId, statusId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить статус фонограммы.`),
      }).send();
    });
  }

  delete(phonogramId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PhonogramRequest.delete,
        parameter: {phonogramId},
        checkRequiredParameter: {phonogramId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить фонограмму.`),
      }).send();
    });
  }

  deletePhonograms(performanceId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: PhonogramRequest.deleteAll,
        parameter: {performanceId},
        checkRequiredParameter: {performanceId},
        requiredResponseParameters: [],
        isShowError: this._isShowError,
        onResolveHandler: () => resolve(),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось удалить фонограммы.`),
      }).send();
    });
  }
}
