import {NTR} from "../../name-to-request";
import {getCodeCheck} from "../../utils/common";
import {getDataRequest} from "../../utils/request";
import {ALL_TEAM_PARAMETERS} from "./parameter";

export default class TeamRequest {
  static getAll({parameters = ALL_TEAM_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.TEAM_ID]: NTR.ZERO,
        [NTR.SIZE]: NTR.ALL,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static getOne({teamId, parameters = ALL_TEAM_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.TEAM_ID]: teamId,
        [NTR.SIZE]: NTR.ZERO,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static title({teamId, title}) {
    return {
      [NTR.SET_TEAM_TITLE]: {
        [NTR.TEAM_ID]: teamId,
        [NTR.TEAM_TITLE]: title,
      }
    };
  }

  static city({teamId, city, cityId}) {
    return {
      [NTR.SET_TEAM_CITY]: {
        [NTR.TEAM_ID]: teamId,
        [NTR.TEAM_CITY]: city,
        [NTR.GOOGLE_CITY]: cityId,
      }
    };
  }

  static cityPrint({teamId, isCityPrint}) {
    return {
      [NTR.SET_TEAM_CITY_PRINT]: {
        [NTR.TEAM_ID]: teamId,
        [NTR.TEAM_CITY_PRINT]: getCodeCheck(isCityPrint),
      }
    };
  }

  static organization({teamId, organizationId}) {
    return {
      [NTR.SET_TEAM_ORGANIZATION]: {
        [NTR.TEAM_ID]: teamId,
        [NTR.ORGANIZATION_ID]: organizationId,
      }
    };
  }
}
