import {Language, LanguageToEngText} from "../../const";
import {DefaultTitleRegistrationField} from "../../const/registration-field";

export const getTitleButton = (languageData, nameValue, defaultValue) => {
  // const nameValue = `${nameField}_text_block_${textIndex}`;

  if (!languageData) {
    return defaultValue || DefaultTitleRegistrationField[nameValue];
  }

  const language = languageData.language;

  if (language === LanguageToEngText[Language.RU]) {
    return languageData.getData(nameValue) || `${DefaultTitleRegistrationField[nameValue]}`;
  }

  return languageData.getData(nameValue) || `${DefaultTitleRegistrationField[nameValue]} (${language})`;
};
