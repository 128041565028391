import {AUTH_TEXT_BLOCKS, ButtonTitle, CITY_TEXT_BLOCKS, CONTACT_TEXT_BLOCKS, DIRECTORS_TEXT_BLOCKS, FILES_TEXT_BLOCKS, PHONOGRAMS_TEXT_BLOCKS, PLAYERS_TEXT_BLOCKS, RegistrationField} from "../const";

export const DefaultTitleRegistrationField = {
  // [RegistrationField.CONTEST]: `Мероприятие`,
  // [RegistrationField.AUTHORIZATION]: `Представьтесь`,
  [RegistrationField.PERFORMANCE]: `Название выступления`,
  [RegistrationField.NOMINATION]: `Номинация`,
  [RegistrationField.AGE_CATEGORY]: `Возрастная категория`,
  [RegistrationField.QUALIFICATION]: `Квалификация`,
  [RegistrationField.ORGANIZATION]: `Название организации`,
  [RegistrationField.TEAM]: `Название коллектива`,
  [RegistrationField.CITY]: `Населенный пункт`,
  [RegistrationField.DIRECTORS]: `Руководители`,
  [RegistrationField.PLAYERS]: `Участники`,
  [RegistrationField.CONTACT]: `Контактное лицо`,
  [RegistrationField.FILES]: `Файлы2`,
  [RegistrationField.FILE]: `Файлы`,
  [RegistrationField.LINK]: `Ссылки`,
  [RegistrationField.PHONOGRAMS]: `Фонограммы`,
  [RegistrationField.START_POINT]: `Начало выступления`,
  [RegistrationField.DURATION]: `Длительность выступления`,
  // [RegistrationField.PAYMENT]: `Оплата`,
  [RegistrationField.NOTE]: `Комментарий`,
  // [RegistrationField.REGISTRATION_POPUP]: `Окно после нажатия кнопки «Отправить заявку»`,

  [`organization_is_not`]: `Я не представляю организацию`,
  [`team_is_not`]: `Я не представляю коллектив`,

  [`authorization_text_block_0`]: AUTH_TEXT_BLOCKS[0].text,


  [`file_text_block_0`]: FILES_TEXT_BLOCKS[0].text,
  [`file_text_block_1`]: FILES_TEXT_BLOCKS[1].text,
  [`file_text_block_2`]: FILES_TEXT_BLOCKS[2].text,
  [`file_text_block_3`]: FILES_TEXT_BLOCKS[3].text,

  [`phonograms_text_block_0`]: PHONOGRAMS_TEXT_BLOCKS[0].text,
  [`phonograms_text_block_1`]: PHONOGRAMS_TEXT_BLOCKS[1].text,
  [`phonograms_text_block_2`]: PHONOGRAMS_TEXT_BLOCKS[2].text,
  [`phonograms_text_block_3`]: PHONOGRAMS_TEXT_BLOCKS[3].text,
  [`phonograms_text_block_4`]: PHONOGRAMS_TEXT_BLOCKS[4].text,
  // [`phonograms_text_block_5`]: PHONOGRAMS_TEXT_BLOCKS[5].text,

  [`players_text_block_0`]: PLAYERS_TEXT_BLOCKS[0].text,
  [`players_text_block_1`]: PLAYERS_TEXT_BLOCKS[1].text,
  [`players_text_block_2`]: PLAYERS_TEXT_BLOCKS[2].text,
  [`players_text_block_3`]: PLAYERS_TEXT_BLOCKS[3].text,
  [`players_text_block_4`]: PLAYERS_TEXT_BLOCKS[4].text,
  [`players_text_block_5`]: PLAYERS_TEXT_BLOCKS[5].text,
  [`players_text_block_6`]: PLAYERS_TEXT_BLOCKS[6].text,
  [`players_text_block_7`]: PLAYERS_TEXT_BLOCKS[7].text,
  [`players_text_block_8`]: PLAYERS_TEXT_BLOCKS[8].text,
  [`players_text_block_9`]: PLAYERS_TEXT_BLOCKS[9].text,

  [`directors_text_block_0`]: DIRECTORS_TEXT_BLOCKS[0].text,
  [`directors_text_block_1`]: DIRECTORS_TEXT_BLOCKS[1].text,
  [`directors_text_block_2`]: DIRECTORS_TEXT_BLOCKS[2].text,
  [`directors_text_block_3`]: DIRECTORS_TEXT_BLOCKS[3].text,
  [`directors_text_block_4`]: DIRECTORS_TEXT_BLOCKS[4].text,
  [`directors_text_block_5`]: DIRECTORS_TEXT_BLOCKS[5].text,
  [`directors_text_block_6`]: DIRECTORS_TEXT_BLOCKS[6].text,
  [`directors_text_block_7`]: DIRECTORS_TEXT_BLOCKS[7].text,
  [`directors_text_block_8`]: DIRECTORS_TEXT_BLOCKS[8].text,

  [`organization_text_block_0`]: `Я не представляю организацию`,
  [`team_text_block_0`]: `Я не представляю коллектив`,

  [`city_text_block_0`]: CITY_TEXT_BLOCKS[0].text,
  [`city_text_block_1`]: CITY_TEXT_BLOCKS[1].text,
  [`city_text_block_2`]: CITY_TEXT_BLOCKS[2].text,

  [`popupRegistrationWithPay_title`]: `Заявка получена`,
  [`popupRegistrationWithPay_text`]: `Ваша заявка получена, ей присвоен номер [[performance_id]].<br>
  Обработка заявки начнется только ПОСЛЕ оплаты регистрационного взноса в размере [[price]] [[currency]] за заявку.`,

  [`popupRegistrationWithoutPay_title`]: `Номер зарегистрирован`,
  [`popupRegistrationWithoutPay_text`]: `Ваша заявка зарегистрирована, ей присвоен номер [[performance_id]].`,

  [`popupConvention_title`]: ``,
  [`popupConvention_text`]: `Нажимая кнопку «Далее», я:<br>
  – принимаю условия <a href="[[contest_rules_link]]" target="_blank">Положения мероприятия</a> «[[contest_title]]»,<br>
  – при этом я даю <a href="[[privacy_policy_ptt]]" target="_blank">Согласие на обработку персональных данных</a> организатору мероприятия,`,

  [`popup_authTitle`]: `Приятно познакомиться!`,
  [`popup_authText`]: `Организатор получил Ваши данные.

  Вам создан личный кабинет. Логин и пароль для входа отправлены на указанный адрес электронной почты.
        
  <i>А теперь  расскажите нам самое главное – детали Вашего выступления! </i>`,

  [`contact_text_block_0`]: CONTACT_TEXT_BLOCKS[0].text,
  [`contact_text_block_1`]: CONTACT_TEXT_BLOCKS[1].text,
  [`contact_text_block_2`]: CONTACT_TEXT_BLOCKS[2].text,

  [`contest_pattern`]: `Для регистрации на мероприятие [[contestTitle]], 
  которое состоится [[contestDate]] по адресу [[contestAddress]], 
  заполните анкету. 
  
  Регистрация до: [[affirmationDeadline]]. 
  
  Оплата до: [[paymentDeadline]].`,


  [`button_ok`]: ButtonTitle.OK,
  [`button_cancel`]: ButtonTitle.CANCEL,
  [`button_back`]: ButtonTitle.BACK,
  [`button_continue`]: ButtonTitle.CONTINUE,
  [`button_clear`]: ButtonTitle.CLEAR,
  [`button_registration`]: `Отправить заявку`,
};


