import {LESS_GREATER_THAN_REGEXP} from "../../const/pattern";
import {ScriptError} from "../../error/script";
import {isNumeric} from "../../utils/common";


export const getIsLessGreatherThanCondition = (condition, values = []) => {
  if (!condition) {
    throw new ScriptError({
      message: `Empty condition - "${condition}"`,
    });
  }

  const match = condition.match(LESS_GREATER_THAN_REGEXP);

  if (!match) {
    throw new ScriptError({
      message: `Match is null`,
    });
  }

  const sign = match[1];
  const value = match[2];
  const signLast = match[3];

  if (!isNumeric(value)) {
    return false;
  }

  if (sign === `<`) {
    if (!signLast) {
      return values.every((item) => +item < +value);
    }

    return values.every((item) => +item <= +value);
  }

  if (sign === `>`) {
    if (!signLast) {
      return values.every((item) => +item > +value);
    }

    return values.every((item) => +item >= +value);
  }

  throw new ScriptError({
    message: `Not expected sign - "${sign}"`,
  });
};
