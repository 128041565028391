import {NTR} from "../../name-to-request";

export const PerformanceParameter = {
  ARCHIVE: {[NTR.PERFORMANCE_ARCHIVE]: NTR.EMPTY},
  AGE_CATEGORY: {[NTR.PERFORMANCE_AGE_CATEGORY]: NTR.EMPTY},

  CID: {[NTR.PERFORMANCE_CID]: NTR.EMPTY},
  CITY: {[NTR.PERFORMANCE_CITY]: NTR.EMPTY},
  CITY_FULL: {[NTR.PERFORMANCE_CITY_FULL]: NTR.EMPTY},
  CITY_TYPE: {[NTR.PERFORMANCE_CITY_TYPE]: NTR.EMPTY},
  CITY_SHORT_TYPE: {[NTR.PERFORMANCE_CITY_STYPE]: NTR.EMPTY},
  COMMENTS: {[NTR.PERFORMANCE_NOTE]: NTR.EMPTY},
  CONTACT: {[NTR.PERFORMANCE_CONTACT]: NTR.EMPTY},
  CONTEST_ID: {[NTR.CONTEST_ID]: NTR.EMPTY},
  CONTEST_ROLE: {[NTR.CONTEST_ROLE]: NTR.EMPTY},
  COUNTRY: {[NTR.PERFORMANCE_COUNTRY]: NTR.EMPTY},

  DATE_CREATION: {[NTR.PERFORMANCE_CREATION_DATE]: NTR.EMPTY},
  DIRECTORS: {[NTR.PERFORMANCE_DIRECTOR]: NTR.EMPTY},
  DOCUMENT_PRINTED: {[NTR.DOCUMENT_PRINTED]: NTR.EMPTY},
  DURATION: {[NTR.PERFORMANCE_TIME_FULL]: NTR.EMPTY},

  ENTRY: {[NTR.PERFORMANCE_ENTRY]: NTR.EMPTY},

  FILES: {[NTR.PERFORMANCE_CONTENT]: NTR.EMPTY},

  GROUP: {[NTR.PERFORMANCE_GROUP]: NTR.EMPTY},

  LINK_ORIGINAL: {[NTR.PERFORMANCE_LINK]: NTR.SOURCE},

  NOMINATION: {[NTR.PERFORMANCE_NOMINATION]: NTR.EMPTY},

  ORGANIZATION: {[NTR.PERFORMANCE_ORGANIZATION_TITLE]: NTR.EMPTY},
  ORGANIZATION_CITY: {[NTR.PERFORMANCE_ORGANIZATION_CITY]: NTR.EMPTY},
  ORGANIZATION_CITY_TYPE: {[NTR.PERFORMANCE_ORGANIZATION_CITY_TYPE]: NTR.EMPTY},
  ORGANIZATION_CITY_SHORT_TYPE: {[NTR.PERFORMANCE_ORGANIZATION_CITY_STYPE]: NTR.EMPTY},
  ORGANIZATION_CITY_PRINT: {[NTR.ORGANIZATION_CITY_PRINT]: NTR.EMPTY},
  ORGANIZATION_ID: {[NTR.PERFORMANCE_ORGANIZATION_ID]: NTR.EMPTY},

  PAYMENT: {[NTR.PERFORMANCE_MONEY]: NTR.EMPTY},
  PHONOGRAM_DATE: {[NTR.PHONOGRAM_DATE]: NTR.EMPTY},
  PHONOGRAM_DURATION: {[NTR.PHONOGRAM_DURATION]: NTR.EMPTY},
  PHONOGRAM_LINK: {[NTR.PHONOGRAM_LINK]: NTR.EMPTY},
  PHONOGRAM_SIZE: {[NTR.PHONOGRAM_SIZE]: NTR.EMPTY},
  PHONOGRAM_START_WITH_POINT: {[NTR.PHONOGRAM_START_WITH_POINT]: NTR.EMPTY},
  PHONOGRAM_STATUS: {[NTR.PERFORMANCE_PHONOGRAM_STATUS]: NTR.EMPTY},
  PHONOGRAM_TITLE: {[NTR.PHONOGRAM_TITLE]: NTR.EMPTY},
  PLAYERS: {[NTR.PERFORMANCE_PLAYER]: NTR.EMPTY},
  PLAYERS_AGE_RANGE: {[NTR.PERFORMANCE_AGE_RANGE]: NTR.EMPTY},
  PLAYERS_COUNT: {[NTR.PERFORMANCE_PLAYER_COUNT]: NTR.EMPTY},
  PLAYERS_NAME_COUNT: {[NTR.PLAYERS_COUNT_NAME_SHOW]: NTR.EMPTY},
  PRIZE: {[NTR.PERFORMANCE_PRIZE]: NTR.EMPTY},
  PRIZE_MANUAL: {[NTR.PERFORMANCE_PRIZE_MANUAL]: NTR.EMPTY},

  RATING: {[NTR.PERFORMANCE_RATING]: NTR.EMPTY},
  RATING_ALL: {[NTR.PERFORMANCE_RATING]: NTR.ALL},
  RATING_SUM: {[NTR.PERFORMANCE_RATING_SUM]: NTR.EMPTY},
  REAL_TIME: {[NTR.PERFORMANCE_REAL_TIME]: NTR.EMPTY},
  REGISTRATOR: {[NTR.PERFORMANCE_ADD_PEOPLE]: NTR.EMPTY},
  REFEREE_PROGRESS: {[NTR.PERFORMANCE_REFEREE]: NTR.EMPTY},
  REFEREE_RATING_SUM: {[NTR.REFEREE_PERFORMANCE_RATING_SUM]: NTR.EMPTY},

  SOURCE: {[NTR.PERFORMANCE_SOURCE]: NTR.EMPTY},

  TEAM: {[NTR.PERFORMANCE_TEAM_TITLE]: NTR.EMPTY},
  TEAM_CITY: {[NTR.PERFORMANCE_TEAM_CITY]: NTR.EMPTY},
  TEAM_CITY_TYPE: {[NTR.PERFORMANCE_TEAM_CITY_TYPE]: NTR.EMPTY},
  TEAM_CITY_SHORT_TYPE: {[NTR.PERFORMANCE_TEAM_CITY_STYPE]: NTR.EMPTY},
  TEAM_CITY_PRINT: {[NTR.TEAM_CITY_PRINT]: NTR.EMPTY},
  TEAM_ID: {[NTR.PERFORMANCE_TEAM_ID]: NTR.EMPTY},
  TIME_START: {[NTR.PERFORMANCE_TIME_START]: NTR.EMPTY},
  TITLE: {[NTR.PERFORMANCE_TITLE]: NTR.EMPTY},
  TURN_ID: {[NTR.PERFORMANCE_TURN_ID]: NTR.EMPTY},

  QUALIFICATION: {[NTR.PERFORMANCE_QUALIFICATION]: NTR.EMPTY},

  USER_FIELD: {[NTR.PERFORMANCE_USER_DATA]: NTR.EMPTY},
};

export const ALL_PERFORMANCE_PARAMETERS = [
  PerformanceParameter.ARCHIVE,
  PerformanceParameter.AGE_CATEGORY,

  PerformanceParameter.CID,
  PerformanceParameter.CITY,
  PerformanceParameter.COMMENTS,
  PerformanceParameter.CONTACT,
  PerformanceParameter.COUNTRY,

  PerformanceParameter.DATE_CREATION,
  PerformanceParameter.DIRECTORS,
  PerformanceParameter.DOCUMENT_PRINTED,
  PerformanceParameter.DURATION,

  PerformanceParameter.ENTRY,

  PerformanceParameter.FILES,

  PerformanceParameter.GROUP,

  PerformanceParameter.LINK_ORIGINAL,

  PerformanceParameter.NOMINATION,

  PerformanceParameter.ORGANIZATION,
  PerformanceParameter.ORGANIZATION_CITY,
  PerformanceParameter.ORGANIZATION_CITY_PRINT,
  PerformanceParameter.ORGANIZATION_ID,

  PerformanceParameter.PAYMENT,
  PerformanceParameter.PHONOGRAM_DATE,
  PerformanceParameter.PHONOGRAM_DURATION,
  PerformanceParameter.PHONOGRAM_SIZE,
  PerformanceParameter.PHONOGRAM_START_WITH_POINT,
  PerformanceParameter.PHONOGRAM_STATUS,
  PerformanceParameter.PHONOGRAM_TITLE,
  PerformanceParameter.PLAYERS,
  PerformanceParameter.PLAYERS_AGE_RANGE,
  PerformanceParameter.PLAYERS_COUNT,
  PerformanceParameter.PLAYERS_NAME_COUNT,
  PerformanceParameter.PRIZE,
  PerformanceParameter.PRIZE_MANUAL,

  PerformanceParameter.RATING,
  PerformanceParameter.RATING_ALL,
  PerformanceParameter.RATING_SUM,
  PerformanceParameter.REGISTRATOR,

  PerformanceParameter.TEAM,
  PerformanceParameter.TEAM_CITY,
  PerformanceParameter.TEAM_CITY_PRINT,
  PerformanceParameter.TEAM_ID,
  PerformanceParameter.TIME_START,
  PerformanceParameter.TITLE,
  PerformanceParameter.TURN_ID,
  PerformanceParameter.SOURCE,

  PerformanceParameter.USER_FIELD,

  PerformanceParameter.QUALIFICATION,
];
