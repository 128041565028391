import {EQUALITY_REGEXP} from "../../const/pattern";
import {ScriptError} from "../../error/script";

export const getIsEqualityCondition = (condition, values) => {
  if (!condition) {
    throw new ScriptError({
      message: `Empty condition - "${condition}"`,
    });
  }

  const match = condition.match(EQUALITY_REGEXP);

  if (!match) {
    throw new ScriptError({
      message: `Match is null`,
    });
  }

  const val = match[1];

  const equalities = val.split(`||`);
  return values.every((value) => {
    return equalities.includes(String(value));
  });
};
