import {ButtonTitle} from "../../const";
import Popup, {ContentType, PopupSize} from "../../static/popup/popup";

export const renderGoogleCityPopup = (api, popupTitle, city, handler) => {
  Popup.showCustom({
    api,
    size: PopupSize.SMALL,
    title: popupTitle,
    contents: [
      {
        type: ContentType.INPUT_NEW,
        content: {
          type: `googleCity`,
          name: `google`,
          value: city,
          isRequired: true,
        },
      },
      {
        type: ContentType.BUTTONS,
        content: [
          {
            title: ButtonTitle.CANCEL,
            isClose: true,
          },
          {
            title: ButtonTitle.SAVE,
            onClickHandler: handler,
            isClose: true,
            isRequired: true,
          }
        ],
      }
    ]
  });
};
