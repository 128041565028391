import Popup from "../static/popup/popup";
import LangController from "./lang";

export default class LangoSettingNewController {
  constructor() {
    this._onLoadedFile = this._onLoadedFile.bind(this);
  }

  get(name) {
    if (!this._setting) {
      this._setting = {};
    }

    const value = this._setting[name];
    return typeof value !== `undefined` ? value : null;
  }

  set(name, value) {
    if (!this._setting) {
      this._setting = {};
    }
    this._setting[name] = value;
  }

  remove(name) {
    delete this._setting[name];
  }

  save() {
    LangController.setPropertyLango(this._nameSetting, this._setting);
    LangController.saveLango(this._api, this._container, this._onLoadedFile);
  }

  saveNewLang(onResolveHandler = () => {}) {
    // if (this._settingOld && !isEmptyObject(this._settingOld)) {
    //   this._setting = Object.assign({}, this._settingOld, this._setting);
    // }

    LangController.saveNewLang(this._api, this._container, this._nameSetting, this._nameLang, this._setting, () => {
      // if (this._settingOld && !isEmptyObject(this._settingOld)) {
      //   this._settingOld = {};

      //   LangController.setPropertyLang(this._nameSettingOld, this._settingOld);
      //   LangController.save(this._api, this._container, () => {});
      // }

      onResolveHandler();
    });
  }

  _onLoadedFile() {
    Popup.showResolveChangeNotification({text: `Сохранено!`});
  }
}
