import {LENGTH_STRING_REGEXP} from "../../const/pattern";
import {ScriptError} from "../../error/script";

export const getIsLengthStringCondition = (condition = ``, text = ``) => {
  if (!condition) {
    throw new ScriptError({
      message: `Empty condition - "${condition}"`,
    });
  }

  const match = condition.match(LENGTH_STRING_REGEXP);

  if (!match) {
    throw new ScriptError({
      message: `Match is null`,
    });
  }

  const sign = match[1];
  const value = match[2];

  if (sign === `=`) {
    return String(text).length === +value;
  }

  if (sign === `<`) {
    return String(text).length < +value;
  }

  if (sign === `>`) {
    return String(text).length > +value;
  }

  if (sign === `<>`) {
    return String(text).length !== +value;
  }

  if (sign.includes(`<=`) || sign.includes(`=<`)) {
    return String(text).length <= +value;
  }

  if (sign.includes(`>=`) || sign.includes(`=>`)) {
    return String(text).length >= +value;
  }

  return String(text).length === +value;
};
