import DivComponent from "../../../component/base/div";
import TextInputComponent from "../../../component/input/text-input/text-input";
import {RenderPosition, remove, render, replace} from "../../../utils/render";

const DEFAULT_QUALIFICATION = {
  qualificationId: ``,
  title: ``,
};

export default class QualificationController {
  constructor({container, api, qualification = Object.assign({}, DEFAULT_QUALIFICATION), qualifications = [], tabId, nameField, setting, languageController, onQualificationChange}) {
    this._container = container;
    this._api = api;
    this._qualification = qualification;
    this._qualifications = qualifications;
    this._tabId = tabId;
    this._languageController = languageController;
    this._setting = setting;
    this._title = this._getTitleValue();
    this._isRequired = setting.isRequired;
    this._placeholder = setting.placeholder;
    this._nameField = nameField;
    this._isValid = !this._isRequired;
    this._onQualificationChange = onQualificationChange;

    this._contentComponent = null;

    this._onCheckQualificationHandler = this._onCheckQualificationHandler.bind(this);
  }

  _getTitleValue() {
    if (!this._languageController) {
      return this._setting.title;
    }

    return this._languageController[`qualification`];
  }

  get title() {
    return this._title;
  }

  get isValid() {
    return this._isValid;
  }

  render() {
    const oldContentComponent = this._contentComponent;
    this._contentComponent = new DivComponent(`form-registration__fieldQualification`);

    this._renderQualification();

    if (oldContentComponent) {
      replace(this._contentComponent, oldContentComponent);
    } else {
      render(this._container, this._contentComponent.getElement(), RenderPosition.BEFOREEND);
    }

    this._validate();
  }

  remove() {
    remove(this._contentComponent);
  }

  showValidate() {
    this._inputComponent.mode = `active`;
  }

  clearData() {
    this._setQualification(DEFAULT_QUALIFICATION);
    this.render();

    this._onDataChange();
  }

  _getQualificationDropDownItems() {
    return this._qualifications.map((qualification) => {
      return {
        id: qualification.qualificationId,
        text: qualification.title,
      };
    });
  }

  _checkQualificationData() {
    if (!this._qualification.title) {
      this._qualification = DEFAULT_QUALIFICATION;
      return;
    }

    const isUsedId = this._qualifications.some((it) => it.qualificationId === this._qualification.qualificationId);

    if (isUsedId) {
      return;
    }

    const currentQualification = this._qualifications.find((it) => it.qualificationTitle === this._qualification.title);

    if (currentQualification) {
      this._qualification = currentQualification;
      return;
    }

    this._qualification = DEFAULT_QUALIFICATION;
  }

  _renderQualification() {
    this._checkQualificationData();

    this._inputComponent = new TextInputComponent({
      label: this._title,
      value: this._qualification.title,
      inputId: this._qualification.qualificationId,
      placeholder: this._placeholder,
      isReadOnly: true,
      isRequired: this._isRequired,
      dropDownItems: this._getQualificationDropDownItems(),
      onDropDownItemCheck: this._onCheckQualificationHandler,
    });

    render(this._contentComponent.getElement(), this._inputComponent.getElement(), RenderPosition.BEFOREEND);
  }

  _validate() {
    if (!this._isRequired) {
      return;
    }

    this._isValid = this._inputComponent.isValid;
  }

  _getQualificationById(qualificationId) {
    return this._qualifications.find((qualification) => qualification.qualificationId === qualificationId);
  }

  _setQualification(data) {
    this._qualification = Object.assign({}, data);
  }

  _onDataChange() {
    this._validate();
    this._onQualificationChange(this._qualification, this._tabId, this._nameField);
  }

  _onCheckQualificationHandler(qualificationId) {
    const checkQualification = this._getQualificationById(qualificationId);
    if (!checkQualification) {
      return;
    }

    this._setQualification(checkQualification);
    this._onDataChange();
  }
}
