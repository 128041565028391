import {DefaultMailTemplate, MailTemplateId, UseMailTemplate} from "../const/mail-template";
import EmailTemplateData from "../data/email-template/email-template";
import ErrorData from "../data/error/error";
import OrganizationData from "../data/organization/organization";
import {PeopleParameter} from "../data/people/parameter";
import PeopleData from "../data/people/people";
import PerformanceData from "../data/performance/performance";
import PhonogramAPI from "../data/phonogram/api";
import TeamData from "../data/team/team";
import {isEmptyArray} from "../utils/common";
import {getTextName} from "../utils/name";
import GlobalSettingLangController from "./lang-setting/global-setting";
import MailTemplateLangController from "./lang-setting/mail-template-lang";
import GlobalSettingLangoController from "./lango-setting/global-setting";
import {setDurationForRegistration} from "./set-duration-for-registration/set-duration-for-registration";
import {setStartPointForRegistration} from "./set-start-point-for-registration/set-start-point-for-registration";

export default class SetDataRegistrationNew {
  constructor({api}) {
    this._performanceAPI = new PerformanceData({api, isShowError: true});
    this._peopleAPI = new PeopleData({api, isShowError: false});
    this._organizationAPI = new OrganizationData({api, isShowError: false});
    this._teamAPI = new TeamData({api, isShowError: false});
    this._phonogramAPI = new PhonogramAPI({api, isShowError: false});
    this._emailTemplateAPI = new EmailTemplateData({api, isShowError: false});
    this._errorAPI = new ErrorData({api});

    this._globalSettingLangController = new GlobalSettingLangController({api});
    this._globalSettingLangoController = new GlobalSettingLangoController({api});
    this._mailTemplateLangController = new MailTemplateLangController({api});
  }

  createPerformance({performanceId, performanceTitle}) {
    // return Promise.reject();
    return this._performanceAPI.setTitle(performanceId, performanceTitle);
  }

  setContest({performanceId, contestId}) {
    return this._performanceAPI.setContest(performanceId, contestId)
    .catch(() => {});
  }

  setPerformanceCity({performanceId, city, googleId, isCityText = false}) {
    if (isCityText || googleId === ``) {
      return Promise.resolve();
    }

    return this._performanceAPI.setCity(performanceId, city, googleId)
    .catch(() => {});
  }

  setNomination({performanceId, nominationId}) {
    if (!nominationId) {
      return Promise.resolve();
    }

    return this._performanceAPI.setNomination(performanceId, nominationId)
    .catch(() => {});
  }

  setNominationNote({performanceId, noteNomination}) {
    if (!noteNomination) {
      return Promise.resolve();
    }

    return this._performanceAPI.setNote(performanceId, `Название инструмента: ${noteNomination}`)
    .catch(() => {});
  }

  setDirectors({performanceId, directors, isNoDirector}) {
    if (isNoDirector) {
      return this._performanceAPI.setNoDirectors(performanceId)
      .then(() => [])
      .catch(() => {});
    }

    if (!directors || isEmptyArray(directors)) {
      return Promise.resolve([]);
    }

    return Promise.all(directors.map((director) => this.setDirector(director, performanceId)));
  }

  setDirector(director, performanceId) {
    return this._peopleAPI.addPeople(director)
    .then((peopleId) => {
      director.peopleId = peopleId;
    })
    .then(() => {
      const positions = director.positions.map((position) => position.title);
      return Promise.all([
        this._performanceAPI.setDirector(performanceId, director.peopleId, positions),
        this.setPeopleEmail(director),
        this.setPeoplePhone(director),
      ]);
    })
    .then(() => director)
    .catch(() => {});
  }

  setPeopleEmail(people) {
    if (people.emails && people.emails[0] && people.emails[0].mail && people.hasEmailInput) {
      return this._peopleAPI.addEmail(people.peopleId, people.emails[0].mail);
    }

    return Promise.resolve();
  }

  setPeoplePhone(people) {
    if (people.phones && people.phones[0] && people.phones[0].phone && people.hasPhoneInput) {
      return this._peopleAPI.addPhone(people.peopleId, people.phones[0].phone);
    }

    return Promise.resolve();
  }

  setPlayerCount({performanceId, playerCount}) {
    if (!playerCount) {
      return Promise.resolve();
    }

    return this._performanceAPI.setPlayerCount(performanceId, playerCount)
    .catch(() => {});
  }

  setPlayers({performanceId, players}) {
    if (!players || isEmptyArray(players)) {
      return Promise.resolve([]);
    }

    return Promise.all(players.map((player) => this.setPlayer(player, performanceId)));
  }

  setPlayer(player, performanceId) {
    return this._peopleAPI.addPeople(player)
    .then((peopleId) => {
      player.peopleId = peopleId;
    })
    .then(() => {
      return Promise.all([
        this._performanceAPI.setPlayer(performanceId, player.peopleId),
        this.setPeopleEmail(player),
        this.setPeoplePhone(player),
        player.note ? this._performanceAPI.setNote(performanceId, `Для ${getTextName(player)} ${player.noteText}`) : null,
        (player.userFields && !isEmptyArray(player.userFields)) ? this._performanceAPI.setUserField(player.userFields[0].userFieldId, player.peopleId, player.userFields[0].value) : null,
      ]);
    })
    .then(() => player)
    .catch(() => {});
  }

  setEmailRegistrator({registrator}) {
    if (!registrator || !registrator.emails || !registrator.emails[0] || !registrator.emails[0].state || !registrator.emails[0].state === `delete`) {
      return Promise.resolve();
    }

    return this._peopleAPI.addEmail(registrator.peopleId, registrator.emails[0].mail);
  }

  setOrganization({performanceId, organizationId, organizationTitle, isOrganizationRepresent}) {
    if (!isOrganizationRepresent && !organizationId && !organizationTitle) {
      return Promise.resolve(``);
    }

    let newOrganizationId = `0`;

    if (isOrganizationRepresent) {
      return this._performanceAPI.setOrganizationForce(performanceId, newOrganizationId)
      .then(() => newOrganizationId)
      .catch(() => {});
    }

    return this._organizationAPI.createOrganization(organizationTitle)
    .then((newId) => {
      newOrganizationId = newId;
    })
    .then(() => this._performanceAPI.setOrganizationForce(performanceId, newOrganizationId))
    .then(() => newOrganizationId)
    .catch(() => {});
  }

  setOrganizationCity({organizationId, city, googleId, isCityText}) {
    if (!organizationId || organizationId === `0` || !googleId || isCityText) {
      return Promise.resolve();
    }

    return this._organizationAPI.setCity(organizationId, city, googleId);
  }

  setTeam({performanceId, teamId, teamTitle, isTeamRepresent}) {
    if (!isTeamRepresent && !teamId && !teamTitle) {
      return Promise.resolve(``);
    }

    let newTeamId = `0`;

    if (isTeamRepresent) {
      return this._performanceAPI.setTeam(performanceId, newTeamId)
      .then(() => newTeamId)
      .catch(() => {});
    }

    return this._teamAPI.createTeam(teamTitle)
    .then((newId) => {
      newTeamId = newId;
    })
    .then(() => this._performanceAPI.setTeamForce(performanceId, newTeamId))
    .then(() => newTeamId)
    .catch(() => {});
  }

  setTeamCity({teamId, city, googleId, isCityText}) {
    if (!teamId || teamId === `0` || !googleId || isCityText) {
      return Promise.resolve();
    }

    return this._teamAPI.setCity(teamId, city, googleId);
  }

  setTeamOrganization({teamId, organizationId}) {
    if (!teamId || teamId === `0` || !organizationId || organizationId === `0`) {
      return Promise.resolve();
    }

    return this._teamAPI.setOrganization(teamId, organizationId);
  }

  setAgeCategory({performanceId, ageCategoryId}) {
    if (!ageCategoryId) {
      return Promise.resolve();
    }

    return this._performanceAPI.setAgeCategory(performanceId, ageCategoryId)
    .catch(() => {});
  }

  setQualification({performanceId, qualificationId}) {
    if (!qualificationId) {
      return Promise.resolve();
    }

    return this._performanceAPI.setQualification(performanceId, qualificationId)
    .catch(() => {});
  }

  setLinks({performanceId, links}) {
    if (isEmptyArray(links)) {
      return Promise.resolve();
    }

    const linksFlat = links.flat();

    return Promise.all(linksFlat.map((link) => this.setLink({performanceId, link: link.link, userFieldId: link.userFieldId})))
    .catch(() => {});
  }

  setLink({performanceId, link, userFieldId}) {
    if (!link) {
      return Promise.resolve();
    }

    return this._performanceAPI.addLink(performanceId, link)
    .then((linkId) => {
      if (userFieldId) {
        return this._performanceAPI.setUserField(userFieldId, performanceId, linkId);
      } else {
        return Promise.resolve();
      }
    });
  }

  setFiles({performanceId, files}) {
    if (isEmptyArray(files)) {
      return Promise.resolve();
    }

    const filesFlat = files.flat();

    return Promise.all(filesFlat.map((file) => this.setFileId({performanceId, file, fileId: file.fileId})))
    .catch(() => {});
  }

  setFileId({performanceId, file, fileId}) {
    if (!fileId) {
      return Promise.resolve();
    }

    return this._performanceAPI.addFile(performanceId, fileId)
    .then(() => this._performanceAPI.setNote(performanceId, `Для выступления загружен файл file_id=${fileId}`))
    .then(() => {
      if (file.userFieldId) {
        return this._performanceAPI.setUserField(file.userFieldId, performanceId, fileId);
      } else {
        return Promise.resolve();
      }
    });
  }

  setPhonograms({performanceId, phonograms}) {
    if (!phonograms || Object.prototype.toString.call(phonograms) !== `[object Array]` || isEmptyArray(phonograms)) {
      if (Object.prototype.toString.call(phonograms) !== `[object Array]`) {
        this._errorAPI.request({
          nameError: `Registration error`,
          message: `error RegistrationData.setPhonograms: data: ${JSON.stringify(phonograms)}`,
        });
      }

      return Promise.resolve();
    }

    const phonogramsFlat = phonograms.flat();

    return Promise.all(phonogramsFlat.map((phonogram) => this.setPhonogram({performanceId, phonogramId: phonogram.phonogramId, isRequiredPhonogram: phonogram.isRequiredPhonogram})))
    .catch(() => {});
  }

  setPhonogram({performanceId, phonogramId, isRequiredPhonogram = false}) {
    if (!isRequiredPhonogram && !phonogramId) {
      return Promise.resolve();
    }

    if (isRequiredPhonogram) {
      return this._performanceAPI.setIsRequiredPhonogram(performanceId, isRequiredPhonogram)
      .catch(() => {});
    }

    return this._performanceAPI.setPhonogram(performanceId, phonogramId)
    .catch(() => {});
  }

  setStartPoint(data) {
    return setStartPointForRegistration(data, this._phonogramAPI);
  }

  setDuration(data) {
    return setDurationForRegistration(data, this._performanceAPI);
  }

  setNotes({performanceId, notesNew}) {
    if (isEmptyArray(notesNew)) {
      return Promise.resolve();
    }

    return Promise.all(notesNew.map((note) => {
      return this.setNote({performanceId, note: note.fullText});
    }))
    .catch(() => {});
  }

  setNote({performanceId, note}) {
    if (!note) {
      return Promise.resolve();
    }

    return this._performanceAPI.setNote(performanceId, note);
  }

  setRegistration({performanceId}) {
    return this._performanceAPI.setRegistration(performanceId)
    .catch(() => {});
  }

  setContactPeople({performanceId, contact, registrator}) {
    if (!contact) {
      return Promise.resolve(``);
    }

    const contactPeopleId = (contact && contact.peopleId) ? contact.peopleId : registrator.peopleId;

    return this._performanceAPI.setContactPeople(performanceId, contactPeopleId)
    .then(() => contactPeopleId)
    .catch(() => {});
  }

  setNoteCityText({performanceId, city, isCityText}) { // аналог noteGoogleMaps, есои город введен вручную
    if (!isCityText) {
      return Promise.resolve();
    }

    return this._performanceAPI.setNote(performanceId, `Пользователь вручную указал населенный пункт ${city}`)
    .catch(() => {});
  }

  setGroup({performanceId, groupId, isGroupPerformances}) {
    if (!isGroupPerformances) {
      return Promise.resolve(``);
    }

    if (!groupId) {
      return this._performanceAPI.createGroup(performanceId)
      .catch(() => {});
    }

    return this._performanceAPI.changeGroup(performanceId, groupId)
    .catch(() => {});
  }

  _getEmailsPeople(peopleId) {
    return this._peopleAPI.getOneData({
      peopleId,
      parameters: [
        PeopleParameter.EMAIL,
      ]
    })
    .then((people) => people.emails);
  }

  _getMailId(people) {
    if (!people) {
      return ``;
    }

    if (people.emails && people.emails[0] && people.emails[0].mailId) {
      return people.emails[0].mailId;
    }

    // const emails = await this._getEmailsPeople(people.peopleId);

    // if (emails[0] && emails[0].mailId) {
    //   return Promise.resolve(emails[0].mailId);
    // }

    return ``;
  }

  _getRegistrationMailTemplate(contest) {
    const useTemplate = UseMailTemplate.PLAYER_REGISTRATION;

    if (this._mailTemplateLangController[useTemplate]) {
      return {
        isTitle: false,
        id: this._mailTemplateLangController[useTemplate]
      };
    }

    const titleOldLang = document.querySelector(`.templateMailRegistration`).dataset.template;

    if (titleOldLang) {
      const mailTemplateId = MailTemplateId[titleOldLang];

      if (mailTemplateId) {
        return {
          isTitle: false,
          id: mailTemplateId
        };
      }

      const template = contest.mailTemplates.find((item) => item.title === titleOldLang);

      if (template) {
        return {
          isTitle: false,
          id: template.mailTemplateId
        };
      }

      return {
        isTitle: true,
        id: titleOldLang
      };
    }

    return {
      isTitle: false,
      id: DefaultMailTemplate[useTemplate],
    };

    // //
    // if (this._mailTemplateLangController.registrationMailTemplateId) {
    //   return {
    //     isTitle: false,
    //     id: this._mailTemplateLangController.registrationMailTemplateId
    //   };
    // }

    // return {
    //   isTitle: true,
    //   id: document.querySelector(`.templateMailRegistration`).dataset.template
    // };

    // //


    // templateMailRegistration: this._mailTemplateLangController.registrationTemplate || document.querySelector(`.templateMailRegistration`).dataset.template,
    // if (this._mailTemplateLangController.registrationTemplate) {
    //   const template = contest.mailTemplates.find((item) => item.mailTemplateId === this._mailTemplateLangController.registrationTemplate);

    //   if (template) {
    //     return template.title;
    //   }
    // }

    // return document.querySelector(`.templateMailRegistration`).dataset.template;
  }

  setRegistrationEmail({performanceId, contactPeopleId, contact, isGroupPerformances, groupId, registrator}, contest) {
    if (isGroupPerformances && !groupId) {
      return Promise.resolve();
    }

    const template = this._getRegistrationMailTemplate(contest);
    // const template = (document.querySelector(`.email_template`)) ? document.querySelector(`.email_template`).innerHTML : `registration`;

    if (template.id === `none`) {
      return Promise.resolve();
    }

    if (!contactPeopleId && !registrator) {
      return Promise.resolve();
    }

    const people = contactPeopleId && contactPeopleId === contact.peopleId ? contact : registrator || null;

    const mailId = this._getMailId(people);

    if (!mailId) {
      this._errorAPI.request({
        nameError: `Registration error`,
        message: `SetDataRegistration.setRegistrationEmail: contactPeopleId: ${contactPeopleId}, contact: ${JSON.stringify(contact)}, registrator: ${JSON.stringify(registrator)}`,
      });
      return Promise.resolve();
    }

    return this._sendMail(template, mailId, performanceId)
    .catch((error) => {
      this._errorAPI.request({
        nameError: `Registration error`,
        message: `SetDataRegistration.setRegistrationEmail: error: ${error}`,
      });
    });
  }

  _sendMail(template, mailId, performanceId) {
    if (!template.isTitle) {
      return this._emailTemplateAPI.sendCustomNew2({
        templateId: template.id,
        mailId,
        performanceId,
      });
    }

    return this._emailTemplateAPI.sendCustom2({
      template: template.id,
      mailId,
      performanceId,
    });
  }

  // setRegistrationEmail({performanceId, contactPeopleId, contact, isGroupPerformances, groupId, registrator}) {
  //   if (isGroupPerformances && !groupId) {
  //     return Promise.resolve();
  //   }

  //   const template = (document.querySelector(`.email_template`)) ? document.querySelector(`.email_template`).innerHTML : `registration`;

  //   if (template === `none`) {
  //     return Promise.resolve();
  //   }

  //   if (!contactPeopleId && !registrator) {
  //     return Promise.resolve();
  //   }

  //   const mailId = this._getMailId(contact, registrator);

  //   const hasContactPeopleMailId = (contactPeopleId && contactPeopleId === contact.peopleId && mailId) || mailId;

  //   if (hasContactPeopleMailId) {
  //     return this._emailTemplateAPI.sendCustom2({
  //       template,
  //       mailId,
  //       // mailId: contact.emails[0].mailId,
  //       performanceId,
  //     })
  //     .catch((error) => {
  //       debugger;
  //     });
  //   } else {
  //     // SetDataError.test({api, error: `Ошибка SetDataRegistration.registrationEmail: data ${data}`});

  //     // return api.getPeople({
  //     //   peopleId: contactPeopleId,
  //     //   parameters: [
  //     //     PeopleParameter.EMAIL,
  //     //   ]
  //     // })
  //     // .then((peopleData) => {
  //     //   if (peopleData.emails && peopleData.emails[0] && peopleData.emails[0].mailId) {
  //     //     return api.setParameter({
  //     //       [NTR.SEND_EMAIL]: {
  //     //         [NTR.TEMPLATE_NAME]: template,
  //     //         [NTR.MAIL_ID]: peopleData.emails[0].mailId,
  //     //         [NTR.PERFORMANCE_ID]: performanceId,
  //     //       }
  //     //     });
  //     //   } else {
  //     //     return api.setParameter({
  //     //       [NTR.SEND_EMAIL]: {
  //     //         [NTR.TEMPLATE_NAME]: template,
  //     //         [NTR.MAIL_ID]: '',
  //     //         [NTR.PEOPLE_ID]: contactPeopleId,
  //     //         [NTR.PERFORMANCE_ID]: performanceId,
  //     //       }
  //     //     });
  //     //   }
  //     // })

  //     return Promise.resolve();
  //   }
  // }

  _getVerificationMail() {
    if (this._globalSettingLangController.verificationMail && this._globalSettingLangController.verificationMail.mailId) {
      return this._globalSettingLangController.verificationMail.mailId;
    }

    if (this._globalSettingLangoController.verificationMail && this._globalSettingLangoController.verificationMail.mailId) {
      return this._globalSettingLangoController.verificationMail.mailId;
    }

    if (document.querySelector(`.email_id_verification`)) {
      return document.querySelector(`.email_id_verification`).textContent;
    }

    return null;
  }

  _getVerficationMailTemplate(contest) {
    const useTemplate = UseMailTemplate.ORGANIZATION_REGISTRATION;

    if (this._mailTemplateLangController[useTemplate]) {
      return {
        isTitle: false,
        id: this._mailTemplateLangController[useTemplate]
      };
    }

    const titleOldLang = document.querySelector(`.templateMailRegistrationVerification`).dataset.template;

    if (titleOldLang) {
      const mailTemplateId = MailTemplateId[titleOldLang];

      if (mailTemplateId) {
        return {
          isTitle: false,
          id: mailTemplateId
        };
      }

      const template = contest.mailTemplates.find((item) => item.title === titleOldLang);

      if (template) {
        return {
          isTitle: false,
          id: template.mailTemplateId
        };
      }

      return {
        isTitle: true,
        id: titleOldLang
      };
    }

    return {
      isTitle: false,
      id: DefaultMailTemplate[useTemplate],
    };

    // //

    // if (this._mailTemplateLangController.registrationVerificationMailTemplateId) {
    //   return {
    //     isTitle: false,
    //     id: this._mailTemplateLangController.registrationVerificationMailTemplateId
    //   };
    // }

    // return {
    //   isTitle: true,
    //   id: document.querySelector(`.templateMailRegistrationVerification`).dataset.template
    // };

    // //

    // templateMailRegistrationVerification: this._mailTemplateLangController.registrationVerificationTemplate || document.querySelector(`.templateMailRegistrationVerification`).dataset.template,

    // if (this._mailTemplateLangController.registrationVerificationTemplate) {
    //   const template = contest.mailTemplates.find((item) => item.mailTemplateId === this._mailTemplateLangController.registrationVerificationTemplate);

    //   if (template) {
    //     return template.title;
    //   }
    // }

    // return document.querySelector(`.templateMailRegistrationVerification`).dataset.template;
  }

  setContactVerificationEmail({performanceId, isGroupPerformances, groupId}, contest) {
    // debugger;
    // return Promise.resolve();
    const mailIdVerification = this._getVerificationMail();
    if (!mailIdVerification) {
      return Promise.resolve();
    }

    const templateVerification = this._getVerficationMailTemplate(contest);
    // const templateVerification = (document.querySelector(`.email_verification_template`)) ? document.querySelector(`.email_verification_template`).textContent : `registration`;

    if (isGroupPerformances && !groupId) {
      return Promise.resolve();
    }

    if (templateVerification.id === `none`) {
      return Promise.resolve();
    }

    return this._sendMail(templateVerification, mailIdVerification, performanceId)
    .catch((error) => {
      debugger;
    });
  }

  // static userFieldPeoples(api, data) {
  //   const {performanceId, userFieldPeoples} = data;
  //   if (userFieldPeoples.length == 0) return null;

  //   return Promise.all(userFieldPeoples.map((userFieldPeople) => {
  //     return SetDataRegistration.userFieldPeople(api, {performanceId, userFieldPeople})
  //   }))
  //   .catch((error) => {
  //     debugger;
  //   });
  // }

  // static userFieldPeople(api, data) {
  //   const {performanceId, userFieldPeople} = data;
  //   if (!userFieldPeople) {
  //     return Promise.resolve();
  //   }

  //   return Promise.all(userFieldPeople.peoples.map((people) => {
  //     return this._performanceAPI.setUserField(userFieldPeople.fieldId, performanceId, people.peopleId);
  //     // return SetDataPerformance.userField(api, userFieldPeople.fieldId, performanceId, people.peopleId)
  //   }));
  // }

  // static userFieldDropDowns(api, data) {
  //   const {performanceId, userDropDowns} = data;
  //   if (userDropDowns.length == 0) {
  //     return Promise.resolve();
  //   }

  //   return Promise.all(userDropDowns.map((userDropDown) => SetDataRegistration.userDropDown(api, {performanceId, userDropDown})))
  //   .catch((error) => {
  //     debugger;
  //   });
  // }

  // static userDropDown(api, data) {
  //   const {performanceId, userDropDown} = data;
  //   if (!userDropDown) {
  //     return Promise.resolve();
  //   }

  //   return this._performanceAPI.setUserField(userDropDown.fieldId, performanceId, userDropDown.dropDown);
  //   // return SetDataPerformance.userField(api, userDropDown.fieldId, performanceId, userDropDown.dropDown);
  // }

  // static userTextFields(api, data) {
  //   const {performanceId, userTextFields} = data;
  //   if (userTextFields.length == 0) {
  //     return Promise.resolve();
  //   }

  //   return Promise.all(userTextFields.map((userTextField) => SetDataRegistration.userTextField(api, {performanceId, userTextField})))
  //   .catch((error) => {
  //     debugger;
  //   });
  // }

  // static userTextField(api, data) {
  //   const {performanceId, userTextField} = data;
  //   if (!userTextField) {
  //     return Promise.resolve();
  //   }

  //   return this._performanceAPI.setUserField(userTextField.fieldId, performanceId, userTextField.text);
  //   // return SetDataPerformance.userField(api, userTextField.fieldId, performanceId, userTextField.text);
  // }

  // static userNumberFields(api, data) {
  //   const {performanceId, userNumberFields} = data;
  //   if (userNumberFields.length == 0) {
  //     return Promise.resolve();
  //   }

  //   return Promise.all(userNumberFields.map((userNumberField) => SetDataRegistration.userNumberField(api, {performanceId, userNumberField})))
  //   .catch((error) => {
  //     debugger;
  //   });
  // }

  // static userNumberField(api, data) {
  //   const {performanceId, userNumberField} = data;
  //   if (!userNumberField) {
  //     return Promise.resolve();
  //   }

  //   return this._performanceAPI.setUserField(userNumberField.fieldId, performanceId, userNumberField.number);
  //   // return SetDataPerformance.userField(api, userNumberField.fieldId, performanceId, userNumberField.number);
  // }

  // static userDateFields(api, data) {
  //   const {performanceId, userDateFields} = data;
  //   if (userDateFields.length == 0) {
  //     return Promise.resolve();
  //   }

  //   return Promise.all(userDateFields.map((userDateField) => SetDataRegistration.userDateField(api, {performanceId, userDateField})))
  //   .catch((error) => {
  //     debugger;
  //   });
  // }

  // static userDateField(api, data) {
  //   const {performanceId, userDateField} = data;
  //   if (!userDateField) {
  //     return Promise.resolve();
  //   }

  //   return this._performanceAPI.setUserField(userDateField.fieldId, performanceId, userDateField.date);
  //   // return SetDataPerformance.userField(api, userDateField.fieldId, performanceId, userDateField.date);
  // }

  // static userDatetimeFields(api, data) {
  //   const {performanceId, userDatetimeFields} = data;
  //   if (userDatetimeFields.length == 0) {
  //     return Promise.resolve();
  //   }

  //   return Promise.all(userDatetimeFields.map((userDatetimeField) => SetDataRegistration.userDatetimeField(api, {performanceId, userDatetimeField})))
  //   .catch((error) => {
  //     debugger;
  //   });
  // }

  // static userDatetimeField(api, data) {
  //   const {performanceId, userDatetimeField} = data;
  //   if (!userDatetimeField) {
  //     return Promise.resolve();
  //   }

  //   return this._performanceAPI.setUserField(userDatetimeField.fieldId, performanceId, userDatetimeField.datetime);
  //   // return SetDataPerformance.userField(api, userDatetimeField.fieldId, performanceId, userDatetimeField.datetime);
  // }

  // static userLinkFields(api, data) {
  //   const {performanceId, userLinkFields} = data;
  //   if (userLinkFields.length == 0) {
  //     return Promise.resolve();
  //   }

  //   return Promise.all(userLinkFields.map((userLinkField) => SetDataRegistration.userNumberField(api, {performanceId, userLinkField})))
  //   .catch((error) => {
  //     debugger;
  //   });
  // }

  // static userLinkField(api, data) {
  //   const {performanceId, userLinkField} = data;
  //   if (!userLinkField) return null;

  //   return SetDataPerformance.userField(api, userLinkField.fieldId, performanceId, userLinkField.link);
  // }

}
