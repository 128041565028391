import {getChangedQuotText2} from "../../utils/common";

export const getIsIncludes = (condition, varValues) => {
  if (!condition) {
    return false;
  }
  condition = getChangedQuotText2(condition);

  const conditions = condition.split(`||`);

  return varValues.every((value) => {
    value = getChangedQuotText2(value);
    return conditions.some((cond) => {
      return value.includes(cond);
    });
  });
};


// varValues = [[per_title]] [[team_title]]

// тут includes относится к тексту


// const equalities = val.split(`||`);
//   return values.every((value) => {
//     return equalities.includes(value);
//   });
