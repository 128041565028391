import {checkAccess} from '../../utils/common';
import RefereeParsing from './parsing';


export default class RefereeModel {
  constructor(refereeId, data) {
    this.refereeId = refereeId;

    this.lastname = data.lastname || ``;
    this.surname = data.surname || ``;
    this.middlename = data.middlename || ``;
    this.criterias = RefereeParsing.criteria(data);
    this.person = String(data.person) || ``;
    this.peopleId = data.people_id || ``;

    this.access = RefereeModel.getAccess(data);
  }

  toRAW() {
  }

  static newReferee(refereeId, data) {
    return new RefereeModel(refereeId, data);
  }

  static parseReferee(data) {
    const refereeId = Object.keys(data)[0];
    return RefereeModel.newReferee(refereeId, data[refereeId]);
  }

  static getAccess(data) {
    return {
      criterias: checkAccess(data.referee_criteria),
    };
  }

  static clone(refereeId, data) {
    return new RefereeModel(refereeId, data.toRAW());
  }
}
