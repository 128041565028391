export const definePrize = (prizeId, prizes) => {
  const prizeIndex = prizes.findIndex((item) => item.prizeId === prizeId);

  if (prizeIndex === -1) {
    return {
      title: ``,
      prizeId: ``,
      iconId: ``,
    };
  }

  return {
    title: prizes[prizeIndex].title,
    prizeId,
    iconId: prizeIndex,
  };
};
