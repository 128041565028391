import {NTR} from "../../name-to-request";

export default class SearchRequest {
  static get({area, text, parameter, filter}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.SEARCH]: {
          [area]: {
            [NTR.TEXT]: text,
            [NTR.DATA]: parameter,
            [NTR.FILTER]: filter
          }
        }
      }
    };
  }
}
