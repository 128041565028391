import API from "../../../api";
import ParagraphComponent from "../../../component/base/paragraph";
import TextInputComponent from "../../../component/input/text-input/text-input";
import ContestModel from "../../../data/contest/model";
import LinkData from "../../../data/link/link";
import {openPage} from "../../../utils/common";
import {RenderPosition, render} from "../../../utils/render";

export const renderContestsList = (container, json) => {
  const contests = ContestModel.parseContests(json);
  const nowDate = Math.floor(Date.now() / 1000);

  const leadText = new ParagraphComponent({
    className: `master__text`,
    text: `<b>Выберите мероприятие для подачи заявки из выпадающего списка ↓</b>`,
  });

  const inputComponent = new TextInputComponent({
    label: `Мероприятие`,
    value: ``,
    prompt: `Выберите мероприятие из выпадающего списка`,
    dropDownItems: contests.map((contest) => {
      const deadlineExpire = contest.affirmationDeadline ? contest.affirmationDeadline < nowDate : false;
      const text = contest.title;

      const comment = deadlineExpire ? `<span style="color: red;"> - Прием заявок завершен</span>` : ``;
      return {
        id: contest.contestId,
        text,
        comment,
      };
    }),

    onDropDownItemCheck: (contestId) => {
      const selectedItem = contests.find((contest) => contest.contestId === contestId);
      if (selectedItem && (!selectedItem.affirmationDeadline || selectedItem.affirmationDeadline > nowDate)) {
        const link = `/registration?id=${contestId}`;
        new LinkData({api: new API()}).getShortLink(link, (shortLink) => {
          openPage(shortLink, `_self`);
        });
      }
    },
  });

  render(container, inputComponent.getElement(), RenderPosition.AFTERBEGIN);
  render(container, leadText.getElement(), RenderPosition.AFTERBEGIN);
};

