import {NTR} from "../../name-to-request";
import RequestController from "../../static/request/request";
import TeamModel from "./model";
import TeamParsing from "./parsing";
import TeamRequest from "./request";

export default class TeamData {
  constructor({api, isShowError = true}) {
    this._api = api;
    this._isShowError = isShowError;
  }

  getAllData({parameters}) {
    return new Promise((resolve) => {
      new RequestController({
        api: this._api,
        getRequest: TeamRequest.getAll,
        parameter: {
          parameters,
        },
        checkRequiredParameter: {},
        requiredResponseParameters: [],
        parseResponse: TeamModel.parseTeams,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по коллективам.`),
      }).send();
    });
  }

  getOneData({teamId, parameters}) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: TeamRequest.getOne,
        parameter: {
          teamId,
          parameters,
        },
        checkRequiredParameter: {teamId},
        requiredResponseParameters: [],
        parseResponse: (data) => TeamModel.parseTeam(teamId, data),
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по коллективу.`),
      }).send();
    });
  }

  createTeam(title) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: TeamRequest.title,
        parameter: {title, teamId: NTR.ZERO},
        checkRequiredParameter: {title},
        requiredResponseParameters: [NTR.TEAM_ID],
        parseResponse: TeamParsing.teamId,
        onResolveHandler: (teamId) => resolve(teamId),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось создать коллектив.`),
      }).send();
    });
  }

  setTitle(teamId, title) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: TeamRequest.title,
        parameter: {title, teamId},
        checkRequiredParameter: {title, teamId},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось изменить название коллектива.`),
      }).send();
    });
  }

  setCity(teamId, city, cityId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: TeamRequest.city,
        parameter: {teamId, city, cityId},
        checkRequiredParameter: {teamId, city, cityId},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить город коллективу.`),
      }).send();
    });
  }

  setCityPrint(teamId, isCityPrint) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: TeamRequest.cityPrint,
        parameter: {teamId, isCityPrint},
        checkRequiredParameter: {teamId, isCityPrint},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить город коллективу.`),
      }).send();
    });
  }

  setOrganization(teamId, organizationId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: TeamRequest.organization,
        parameter: {teamId, organizationId},
        checkRequiredParameter: {teamId, organizationId},
        requiredResponseParameters: [],
        onResolveHandler: () => resolve(),
        isShowError: this._isShowError,
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить организацию коллективу.`),
      }).send();
    });
  }
}
