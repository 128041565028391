import {isEmptyObject} from "../../utils/common";
import LangController from "../lang";
import LangoSettingNewController from "../lango-setting_new";


export default class LanguageLangoController extends LangoSettingNewController {
  constructor({api, lang, language}) {
    super();

    this._api = api;

    this._container = document.querySelector(`body`);
    this._lang = lang; // краткая форма
    this._language = language;
    this._nameSetting = `${language}Language`;

    this._nameLang = `lango_${language}`;
    this._setting = LangController.getSettingNewLang(window[this._nameSetting]);
  }

  isFull() {
    if (!this._setting) {
      return false;
    }
    return !isEmptyObject(this._setting);
  }

  // getUserFieldTitle(name) {
  //   return this.get(name);
  // }

  setUserFieldTitle(name, data) {
    this.set(name, data);
  }

  getData(name) {
    return this.get(name);
  }

  setData(name, data) {
    this.set(name, data);
  }

  save() {
    this.saveNewLang();
  }

  get language() {
    return this._language;
  }

  get lang() {
    return this._lang;
  }

  get performance() {
    return this.get(`performanceTitle`);
  }

  get nomination() {
    return this.get(`nomination`);
  }

  // eslint-disable-next-line camelcase
  get age_category() {
    return this.get(`ageCategory`);
  }

  get qualification() {
    return this.get(`qualification`);
  }

  get organization() {
    return this.get(`organizationTitle`);
  }

  get team() {
    return this.get(`teamTitle`);
  }

  get city() {
    return this.get(`city`);
  }

  get players() {
    return this.get(`players`);
  }

  get directors() {
    return this.get(`directors`);
  }

  get contact() {
    return this.get(`contact`);
  }

  get file() {
    return this.get(`file`);
  }

  get link() {
    return this.get(`link`);
  }

  get phonograms() {
    return this.get(`phonograms`);
  }

  // eslint-disable-next-line camelcase
  get start_point() {
    return this.get(`startPoint`) || `Начало выступления (${this._language})`;
  }

  get duration() {
    return this.get(`duration`) || `Длительность выступления (${this._language})`;
  }

  get note() {
    return this.get(`note`) || `Комментарий (${this._language})`;
  }


  set performance(data) {
    this.set(`performanceTitle`, data);
  }

  set nomination(data) {
    this.set(`nomination`, data);
  }

  // eslint-disable-next-line camelcase
  set age_category(data) {
    this.set(`ageCategory`, data);
  }

  set qualification(data) {
    this.set(`qualification`, data);
  }

  set organization(data) {
    this.set(`organizationTitle`, data);
  }

  set team(data) {
    this.set(`teamTitle`, data);
  }

  set city(data) {
    this.set(`city`, data);
  }

  set players(data) {
    this.set(`players`, data);
  }

  set directors(data) {
    this.set(`directors`, data);
  }

  set contact(data) {
    this.set(`contact`, data);
  }

  set file(data) {
    this.set(`file`, data);
  }

  set link(data) {
    this.set(`link`, data);
  }

  set phonograms(data) {
    this.set(`phonograms`, data);
  }

  // eslint-disable-next-line camelcase
  set start_point(data) {
    this.set(`startPoint`, data);
  }

  set duration(data) {
    this.set(`duration`, data);
  }

  set note(data) {
    this.set(`note`, data);
  }

}

