import Popup from "../../static/popup/popup";
import {isEmptyArray} from "../../utils/common";
import API from "../../api/index";
import LoadAPI from "../../data/load/api";
import LoadController from "./load";


export default class PhonogramLoadController extends LoadController {
  constructor({api = new API(), container, onLoaded = () => {}}) {
    super();

    this._loadAPI = new LoadAPI({api});
    this._container = container;
    this._onLoaded = onLoaded;

    this._phonogramData = new FormData(); // для загрузки фонограммы

    this._onLoadDoneHandler = this._onLoadDoneHandler.bind(this);
    this._onErrorLoadHandler = this._onErrorLoadHandler.bind(this);
  }

  load(files) {
    const isValidate = this._phonogramValidate(Array.from(files));
    if (!isValidate) {
      return;
    }

    this._phonogram = files[0];
    this._phonogramData.append(`audio`, this._phonogram, `phonogram-` + this._phonogram.name);

    this._files = [this._phonogram];
    this._renderProgress();

    this._loadStart();
  }

  _loadStart() {
    this._loadAPI.loadPhonogram(this._phonogramData, this._getAbortSignal())
    .then(this._onLoadDoneHandler)
    .catch(this._onErrorLoadHandler);
  }

  _phonogramValidate(files) {
    const maxFileSize = 20971520; // 20 мегабайт
    const FileType = [`audio/mp3`, `audio/mpeg`];

    let errorText = [];
    let isWMA = false;

    files.forEach(({type, size}) => {
      const errors = [];
      if (!FileType.includes(type)) {
        errors.push(`в формате .mp3`);
      }
      if (size > maxFileSize) {
        errors.push(`не больше 20 Мбайт`);
      }

      if (!isWMA && type === `audio/x-ms-wma`) {
        isWMA = true;
      }

      if (!isEmptyArray(errors)) {
        errorText.push(`Загрузите файл ${errors.join(`, `)}`);
      }
    });

    if (isEmptyArray(errorText)) {
      return true;
    }

    const text = `<b>${errorText.join(` `)}</b>
      ${isWMA ? `<br>Если вы хотите загрузить фонограмму в формате .wma, то свяжитесь с организатором или технической поддержкой.` : ``}`;
    Popup.showWarning({text});

    return false;
  }

  _onLoadDoneHandler(data) {
    this._showProgressLoad(this._phonogram.size, this._phonogram.size);
    this._removeProgress();

    this._onLoaded(data.phonogramId, this._phonogram.name, data.duration);
  }

  _onErrorLoadHandler() {
    this._removeProgress();
  }
}
