import {SearchArea} from "../../const";
import RequestController from "../../static/request/request";
import SearchModel from "./model";
import SearchRequest from "./request";

// const EMPTY_DATA = {
//   performances: [],
//   teams: [],
//   organizations: [],
// };

export default class SearchAPI {
  constructor({api, isShowError = true}) {
    this._api = api;
    this._isShowError = isShowError;
  }

  getPerformance({text, parameter, filter}) {
    if (!text) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: SearchRequest.get,
        parameter: {area: SearchArea.PERFORMANCE, text, parameter, filter},
        checkRequiredParameter: {area: SearchArea.PERFORMANCE, text},
        requiredResponseParameters: [SearchArea.PERFORMANCE],
        parseResponse: SearchModel.parse,
        onResolveHandler: (data) => resolve(data.performances),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось произвести поиск.`),
      }).send();
    });
  }

  getTeam({text, parameter, filter}) {
    if (!text) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: SearchRequest.get,
        parameter: {area: SearchArea.TEAM, text, parameter, filter},
        checkRequiredParameter: {area: SearchArea.TEAM, text},
        requiredResponseParameters: [SearchArea.TEAM],
        parseResponse: SearchModel.parse,
        onResolveHandler: (data) => resolve(data.teams),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось произвести поиск.`),
      }).send();
    });
  }

  getOrganization({text, parameter, filter}) {
    if (!text) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: SearchRequest.get,
        parameter: {area: SearchArea.ORGANIZATION, text, parameter, filter},
        checkRequiredParameter: {area: SearchArea.ORGANIZATION, text},
        requiredResponseParameters: [SearchArea.ORGANIZATION],
        parseResponse: SearchModel.parse,
        onResolveHandler: (data) => resolve(data.organizations),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось произвести поиск.`),
      }).send();
    });
  }

  getCity({text, parameter, filter}) {
    if (!text) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: SearchRequest.get,
        parameter: {area: SearchArea.CITY, text, parameter, filter},
        checkRequiredParameter: {area: SearchArea.CITY, text},
        requiredResponseParameters: [SearchArea.CITY],
        parseResponse: SearchModel.parse,
        onResolveHandler: (data) => resolve(data.cities),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось произвести поиск.`),
      }).send();
    });
  }

  getPeople({text, parameter, filter}) {
    if (!text) {
      return Promise.resolve([]);
    }

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        getRequest: SearchRequest.get,
        parameter: {area: SearchArea.PEOPLE, text, parameter, filter},
        checkRequiredParameter: {area: SearchArea.PEOPLE, text},
        requiredResponseParameters: [SearchArea.PEOPLE],
        parseResponse: SearchModel.parse,
        onResolveHandler: (data) => resolve(data.peoples),
        onRejectHandler: (error) => reject(error),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось произвести поиск.`),
      }).send();
    });
  }
}
