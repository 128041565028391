import {NTR} from "../../name-to-request";

export default class PhonogramRequest {
  static setTitle({phonogramId, title}) {
    return {
      [NTR.SET_PHONOGRAM_TITLE]: {
        [NTR.PHONOGRAM_ID]: phonogramId,
        [NTR.TITLE]: title
      }
    };
  }

  static setStartWithPoint({phonogramId, startPointId}) {
    return {
      [NTR.SET_PHONOGRAM_START_WITH_POINT]: {
        [NTR.PHONOGRAM_ID]: phonogramId,
        [NTR.START_WITH_POINT]: startPointId
      }
    };
  }

  static setStatus({phonogramId, statusId}) {
    return {
      [NTR.SET_PERFORMANCE_PHONOGRAM_STATUS]: {
        [NTR.PHONOGRAM_ID]: phonogramId,
        [NTR.PHONOGRAM_STATUS]: statusId
      }
    };
  }

  static delete({phonogramId}) {
    return {
      [NTR.FILE_DELETE_PHONOGRAM]: phonogramId,
    };
  }

  static deleteAll({performanceId}) {
    return {
      [NTR.FILE_DELETE_PHONOGRAM]: {
        [NTR.PERFORMANCE_ID]: performanceId,
      }
    };
  }


}
