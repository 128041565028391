import DivComponent from "../../../component/base/div";
import TextInputComponent from "../../../component/input/text-input/text-input";
import NominationData from "../../../data/nomination/nomination";
import {NominationParameter} from "../../../data/nomination/parameters";
import {NTR} from "../../../name-to-request";
// import {isEmptyArray} from "../../../utils/common";
import {getTitleRegistrationField} from "../../../utils/get-title-registration-field/get-title-registration-field";
import {RenderPosition, remove, render, replace} from "../../../utils/render";
import FieldRegistrationController from "./field";

const DEFAULT_NOMINATION = {
  nominationId: ``,
  parentId: `0`,
  title: ``,
};

export default class NominationController extends FieldRegistrationController { // class NominationForPageRegistrationController2
  constructor({container, api, isShow = true, nomination = Object.assign({}, DEFAULT_NOMINATION), nominations = [], contestId, tabId, setting, languageController = null, isDisabled, onNominationChange, onMaxCountPlayerChange, onDataChangeHandler = () => {}}) {
    super();

    this._container = container;
    this._api = api;
    this._nomination = nomination;
    this._nominations = nominations;
    // this._contestId = contestId;
    this._tabId = tabId;
    this._setting = setting;
    this._languageController = languageController;
    this._title = this._getTitleValue();
    this._subtitleLevel1 = setting.subtitleLevel1 || `Подноминация`;
    this._subtitleLevel2 = setting.subtitleLevel2 || `Подноминация`;
    this._subtitleLevel3 = setting.subtitleLevel3 || `Подноминация`;
    this._subtitleLevel4 = setting.subtitleLevel4 || `Подноминация`;
    this._subtitleLevel5 = setting.subtitleLevel5 || `Подноминация`;
    this._isRequired = setting.isRequired;
    this._placeholder = setting.placeholder;
    this._isValid = !this._isRequired;
    this._isDisabled = isDisabled;
    this._onNominationChange = onNominationChange;
    this._onMaxCountPlayerChange = onMaxCountPlayerChange;
    this._onDataChangeHandler = onDataChangeHandler;

    this._nominationAPI = new NominationData({api, contestId});

    this._showedNominationComponents = [];

    this._contentComponent = null;

    this._onCheckNominationHandler = this._onCheckNominationHandler.bind(this);

    this._isShow = isShow;
  }

  get isShow() {
    return this._isShow;
  }

  set isShow(data) {
    this._isShow = data;
  }

  show() {
    this._contentComponent.show();
    this.isShow = true;
  }

  hide() {
    this._contentComponent.hide();
    this.isShow = false;
  }

  _getTitleValue() {
    return getTitleRegistrationField(this._languageController, `nomination`, this._setting.title);
  }

  get title() {
    return this._title;
  }

  get isValid() {
    return this._isValid;
  }

  prepare() {
    if (typeof this._setting.isSaveDataAfterRegistration === `undefined`) {
      return;
    }

    if (!this._setting.isSaveDataAfterRegistration) {
      this.clearData();
    }
  }

  render(container) {
    if (container) {
      this._container = container;
      this._contentComponent = null;
    }

    const oldContentComponent = this._contentComponent;
    this._contentComponent = new DivComponent(`form-registration__fieldNomination`);

    this._checkNomination(); // проверка номинации, что она есть в списке для данного конкурса
    this._renderNomination();

    if (oldContentComponent) {
      replace(this._contentComponent, oldContentComponent);
    } else {
      render(this._container, this._contentComponent.getElement(), RenderPosition.BEFOREEND);
    }

    this._updateMaxCount();

    this._validate();
  }

  showValidate() {
  }

  clearData() {
    this._setNomination(DEFAULT_NOMINATION);
    this._showedNominationComponents = [];
    this._isDisabled = false;

    this.render();

    this._onDataChange();
  }

  _checkNomination() {
    // проверка по названию
    // даже если это тот же конкурс все равно лучше проверить
    const nomination = this._nominations.find((item) => item.title == this._nomination.title);

    if (nomination) {
      this._nomination = nomination;
    } else {
      this._nomination = DEFAULT_NOMINATION;
    }

    this._onDataChange();
  }

  _renderNomination() {
    const isBaseNomination = this._getIsBaseNomination(this._nomination.nominationId);

    if (!isBaseNomination) {
      this._getParent(this._nomination.nominationId);
      return;
    }

    const inputComponent = this._getInputComponent({
      label: this._title,
      value: isBaseNomination ? this._nomination.title : ``,
      inputId: isBaseNomination ? this._nomination.nominationId : ``,
      items: this._getDropDownItems(this._getChildrenNominations()),
    });
    this._showedNominationComponents.push(inputComponent);

    render(this._contentComponent.getElement(), inputComponent.getElement(), RenderPosition.BEFOREEND);

    this._getSubNomination(this._nomination.nominationId);
  }

  _getLevelTitle() {
    const level = this._contentComponent.getElement().querySelectorAll(`.dropDownList__wrap`).length;
    switch (level) {
      case 1:
        return this._subtitleLevel1;
      case 2:
        return this._subtitleLevel2;
      case 3:
        return this._subtitleLevel3;
      case 4:
        return this._subtitleLevel4;
      case 5:
        return this._subtitleLevel5;
      default:
        return `Подноминация`;
    }
  }

  _renderSubNomination(subNominations) {
    if (subNominations.length === 0) {
      return;
    }
    const title = this._getLevelTitle();

    this._setNominations(subNominations);

    const nominationItems = this._getDropDownItems(subNominations);

    const inputComponent = this._getInputComponent({
      label: title, 
      items: nominationItems,
    });
    this._showedNominationComponents.push(inputComponent);

    render(this._contentComponent.getElement(), inputComponent.getElement(), RenderPosition.BEFOREEND);
  }

  _renderParentNominations(parentNominations) {
    parentNominations.forEach((nomination, i) => {
      const inputComponent = this._getInputComponent({
        label: i === 0 ? this._title : this._subtitleLevel1,
        value: nomination.title,
        inputId: nomination.nominationId,
        items: this._getDropDownItems(this._getChildrenNominations(nomination.parentId)),
      });

      this._showedNominationComponents.push(inputComponent);
      render(this._contentComponent.getElement(), inputComponent.getElement(), RenderPosition.BEFOREEND);
    });
  }

  _getInputComponent({label = ``, value = ``, inputId = ``, items = []}) {
    return new TextInputComponent({
      label,
      value,
      inputId,
      placeholder: this._placeholder,
      isReadOnly: true,
      isRequired: this._isRequired,
      isDisabled: this._isDisabled,
      dropDownItems: items,
      onDropDownItemCheck: this._onCheckNominationHandler,
    });
  }

  _getParentNominations(parentNominations) {
    if (parentNominations.length === 0) {
      return;
    }

    this._api.getNomination2({
      nominationId: parentNominations[0].nominationId,
      size: NTR.NULL,
      parameters: [
        NominationParameter.DIRECTION_DOWN,
      ]
    })
    .then((response) => this._parseNominations(response))
    .then((nominations) => this._setNominations(nominations))
    .then(() => this._renderParentNominations(parentNominations));
  }

  _getDropDownItems(nominations) {
    return nominations.map((nomination) => {
      return {
        id: nomination.nominationId,
        text: nomination.title,
      };
    });
  }

  _getIsBaseNomination(nominationId) {
    if (nominationId === ``) {
      return true;
    }

    return !!this._nominations
      .filter((nomination) => nomination.parentId === `0`)
      .find((nomination) => nomination.nominationId === nominationId);
  }

  _getParent(nominationId) {
    this._api.getNomination2({
      nominationId,
      size: NTR.NULL,
      parameters: [
        NominationParameter.DIRECTION_UP,
      ]
    })
    .then((response) => this._parseNominations(response))
    .then((nominations) => this._getParentNominations(nominations));
  }

  _getChildrenNominations(parentId = '0') {
    return getChildrenNominations(parentId, this._nominations);
  }

  _parseSubNomination(data, parentId) {
    return getSubNominations(data, parentId);
  }

  _parseNominations(data) {
    return getNominations(data);
  }

  // _getChildrenNominations(parentId = `0`) { // ?
  //   // return this._nominations.filter((nomination) => nomination.parentId === parentId);
  //   return getChildrenNominations(parentId, this._nominations);
  // }

  // _parseSubNomination(data, parentId) { // ?
  //   return getSubNominations(data, parentId);
  //   // let nominations = [];

  //   // for (let nominationId in data) {
  //   //   if (nominationId === `title`) {
  //   //     continue;
  //   //   }
  //   //   const title = data[nominationId].title;
  //   //   nominations.push({nominationId, title, parentId});
  //   // }

  //   // return nominations;
  // }

  // _parseNominations(data) { // ?
  //   return this._getNominations(data);
  // }

  // _getNominations(data, parentId = `0`) { // ?
  //   let nominations = [];

  //   for (let nominationId in data) {
  //     if (nominationId === `title`) {
  //       continue;
  //     }
  //     const nomination = data[nominationId];

  //     const title = nomination.title;
  //     const isChildren = this._isChildrenNominations(nomination);

  //     nominations.push({nominationId, title, parentId});

  //     if (isChildren) {
  //       const nominationItem = this._getNominations(nomination, nominationId);
  //       nominations = nominations.concat(nominationItem);
  //     }
  //   }

  //   return nominations;
  // }

  // _isChildrenNominations(data) {
  //   const keys = Object.keys(data);
  //   return keys.length !== 0 && !(keys.length === 1 && keys[0] === `title`);
  // }

  _removeSubNomination(nominationId) {
    const index = this._showedNominationComponents.findIndex((component) => component.inputId == nominationId);
    if (index + 1 === this._showedNominationComponents.length) {
      return;
    }

    for (let i = index + 1; i < this._showedNominationComponents.length; i++) {
      remove(this._showedNominationComponents[i]);
    }

    this._showedNominationComponents = this._showedNominationComponents.slice(0, index + 1);
  }

  _getSubNomination(parentId) {
    this._api.getNomination2({
      nominationId: parentId,
      size: NTR.NULL,
      parameters: [
        NominationParameter.DIRECTION_DOWN,
      ]
    })
    .then((response) => this._parseSubNomination(response[parentId], parentId))
    .then((nominations) => this._renderSubNomination(nominations));
  }

  _validate() {
    if (!this._isRequired) {
      return;
    }

    this._isValid = this._nomination.nominationId !== ``;
  }

  _getNominationById(nominationId) {
    return this._nominations.find((nomination) => nomination.nominationId == nominationId);
  }

  _setNomination(data) {
    this._nomination = Object.assign({}, data);
  }

  _setNominations(array) {
    const setNomination = new Set(this._nominations);
    array.forEach((item) => setNomination.add(item));

    this._nominations = Array.from(setNomination);
  }

  _updateMaxCount() {
    const nominationId = this._nomination.nominationId;
    if (!nominationId) {
      const maxPlayer = Infinity;
      this._onMaxCountPlayerChange(maxPlayer);
      this._onDataChangeHandler(maxPlayer, this._tabId, this._componentId, `max_count_players`);
      return;
    }

    this._api.getNomination({
      nominationId,
      size: NTR.NULL,
      parameters: [
        NominationParameter.MAX_PLAYER,
      ],
    })
    .then((response) => this._parseMaxCount(response[nominationId]))
    .then((maxCount) => {
      this._onMaxCountPlayerChange(maxCount);
      this._onDataChangeHandler(maxCount, this._tabId, this._componentId, `max_count_players`);
    });
  }

  _parseMaxCount(data) {
    const maxCount = data.document_max_people;

    if (!maxCount || maxCount === null || maxCount === `null`) {
      return Infinity;
    }

    return +maxCount;
  }

  _onDataChange() {
    this._validate();
    this._onNominationChange(this._nomination, this._tabId);
    this._onDataChangeHandler(this._nomination, this._tabId, this._componentId, `nomination`);
    this._updateMaxCount();
  }

  _onCheckNominationHandler(nominationId) {
    const checkNomination = this._getNominationById(nominationId);
    if (!checkNomination) {
      return;
    }

    this._setNomination(checkNomination);

    this._removeSubNomination(nominationId);
    this._getSubNomination(nominationId);

    this._onDataChange();
  }
}
