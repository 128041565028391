import {NTR} from "../../name-to-request";

export const TeamParameter = {
  TITLE: {[NTR.TEAM_TITLE]: NTR.EMPTY},
  CITY: {[NTR.TEAM_CITY]: NTR.EMPTY},
  CITY_ID: {[NTR.TEAM_GOOGLE_CITY]: NTR.EMPTY},
  ALIAS: {[NTR.TEAM_ALIAS]: NTR.EMPTY},
};

export const ALL_TEAM_PARAMETERS = [
  TeamParameter.TITLE,
  TeamParameter.CITY,
  TeamParameter.CITY_ID,
  TeamParameter.ALIAS,
];
