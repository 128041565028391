import RequestController from "../../static/request/request";
import LoadParsing from "./parsing";

export default class LoadAPI {
  constructor({api, isShowError}) {
    this._api = api;
    this._isShowError = isShowError;
  }

  loadPhonogram(formData, abortSignal) {
    const getTextError = ({message}) => {
      if (/wrong file format: .*?/.test(message)) {
        const errorText = message.replace(/wrong file format: (.+)$/, (s, $1) => {
          return `Загружен файл формата <b>${$1}</b>. <br>
          Для предотвращения сложностей во время мероприятия, пожалуйста, загрузите документ в формате <b>mp3</b>`;
        });

        return errorText;
      }

      return `Не удалось загрузить фонограмму`;
    };

    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        requiredResponseParameters: [`phonogram_id`],
        parseResponse: LoadParsing.parsePhonogramId,
        isShowError: this._isShowError,
        onResolveHandler: (data) => {
          resolve(data);
        },
        onRejectHandler: () => reject(),
        // onAbortHandler,
        onRequestErrorHandler: (error) => error.showCustom(getTextError(error)),
      }).sendFormData(formData, abortSignal);
    });
  }

  loadFileChunk(blob, fileId, start, abortSignal) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => reject(),
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось загрузить файл`),
      }).loadFileChunk(blob, fileId, start, abortSignal);
    });
  }

  loadFileDone(fileName, fileId) {
    return new Promise((resolve, reject) => {
      new RequestController({
        api: this._api,
        // parameter: {fileName, fileId},
        // checkRequiredParameter: {fileName, fileId},
        // requiredResponseParameters: [`file_id`],
        parseResponse: LoadParsing.parseFileId,
        isShowError: this._isShowError,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => reject(),
        // onAbortHandler,
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось загрузить файл`),
      }).loadFileDone(fileName, fileId);
    });
  }
}
