import SpanComponent from "../../../component/base/span";
import TextInputComponent from "../../../component/input/text-input/text-input";
import {getTitleRegistrationFieldWithComponentId} from "../../../utils/get-title-registration-field/get-title-registration-field";
import {RenderPosition, remove, render, replace} from "../../../utils/render";
import FieldRegistrationController from "./field";

export default class PerformanceFieldController extends FieldRegistrationController { // class PerformanceForPageRegistrationController
  constructor({container, api, isShow = true, component, setting, colorLabel, languageController = null, performance = ``, tabId, onPerformanceChange}) {
    super();

    this._container = container;
    this._api = api;
    this._tabId = tabId;
    this._setting = setting;
    this._colorLabel = colorLabel;
    this._component = component;
    this._languageController = languageController;

    this._fieldName = `performance`;

    this._componentId = setting.componentId || `000000`;
    this._title = getTitleRegistrationFieldWithComponentId(this._languageController, this._fieldName, this._componentId, this._setting.title);
    this._isRequired = setting.isRequired;
    this._placeholder = setting.placeholder;
    this._qualification = setting.qualification || ``;

    this._isValid = !this._isRequired;
    this._performance = performance;
    this._tabId = tabId;
    this._onDataChange = onPerformanceChange;

    this._inputComponent = null;

    this._onInputChangeHandler = this._onInputChangeHandler.bind(this);

    this._isShow = isShow;
  }

  remove() {
    remove(this._inputComponent);
  }

  // get name() {
  //   return this._fieldName;
  // }

  // get registrationData() {
  //   return this._performance;
  // }

  // get isShow() {
  //   return this._isShow;
  // }

  // set isShow(data) {
  //   this._isShow = data;
  // }

  show() {
    this.isShow = true;
    if (this._inputComponent) {
      this._inputComponent.show();
    }
  }

  hide() {
    this.isShow = false;
    if (this._inputComponent) {
      this._inputComponent.hide();
    }
  }

  // _getTitleValue() {
  //   return getTitleRegistrationField(this._languageController, `performance`, this._setting.title);
  // }

  get title() {
    return this._title;
  }

  get isValid() {
    return this._isValid;
  }

  get isRequired() {
    return this._isRequired;
  }

  render(container) {
    if (container) {
      this._container = container;
      this._inputComponent = null;
    }

    const oldComponent = this._inputComponent;
    this._inputComponent = new TextInputComponent({
      label: this._title,
      extraClassName: `master__field--performance`,
      value: this._performance,
      isRequired: this._isRequired,
      placeholder: this._placeholder,
    });

    if (this._colorLabel) {
      const label = new SpanComponent({
        className: `form-registration__componentLabel`,
      });

      label.getElement().style.backgroundColor = this._colorLabel;

      render(this._inputComponent.getElement(), label.getElement(), RenderPosition.BEFOREEND);
    }

    // this._inputComponent.setChangeHandler(this._onInputChangeHandler); // почему-то при включенном change не уходит фокус
    this._inputComponent.setInputHandler(this._onInputChangeHandler);
    if (oldComponent) {
      replace(this._inputComponent, oldComponent);
    } else {
      render(this._container, this._inputComponent.getElement(), RenderPosition.BEFOREEND);
    }

    if (!this.isShow) {
      this._inputComponent.hide();
    }

    this._validate();

    return this._inputComponent;
  }

  showValidate() {
    this._inputComponent.mode = `active`;
  }

  clearData() {
    this._performance = ``;
    if (this._inputComponent) {
      this._inputComponent.value = ``;
      this._inputComponent.validate();
    }
    this._validate();

    this._onDataChange();
  }

  focusInput() {
    this._inputComponent.focusInput();
  }

  _validate() {
    if (!this._isRequired) {
      return;
    }

    if (!this._inputComponent) {
      return;
    }

    this._isValid = this._inputComponent.isValid;
  }

  _onInputChangeHandler(value) {
    this._performance = value;
    this._validate();
    this._onDataChange(this._performance, this._tabId, this._componentId, `performance`);
  }
}
