export const getFormattedRating = (ratings) => {
  let formattedRating = {};

  ratings.forEach((rating) => {
    if (rating.rating) {
      const requestRating = {
        [rating.criteriaId]: rating.rating
      };
      formattedRating = Object.assign(formattedRating, requestRating);
    }
  });
  return formattedRating;
};
