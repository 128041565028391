import {NTR} from "../../name-to-request";

export default class LinkParsing {
  static authLinkData(response) {
    return response[NTR.AUTH_LINK];
  }

  static parseShortLinkData(response) {
    return response[NTR.SHORT_LINK];
  }

  static paymentLink(data) {
    const link = data.payment_link;

    return link && link !== NTR.NO_ACCESS ? link : NTR.EMPTY;
  }

  static paymentSystem(data) {
    const paymentSystem = data.payment_system;

    return paymentSystem && paymentSystem !== NTR.NO_ACCESS ? paymentSystem : NTR.EMPTY;
  }

  static payerTitle(data) {
    const title = data.payer_title;

    return title && title !== NTR.NO_ACCESS ? title : NTR.EMPTY;
  }

  static peopleId(data) {
    const peopleId = data.people_id;

    return peopleId && peopleId !== NTR.NO_ACCESS ? peopleId : NTR.EMPTY;
  }

  static publicKey(data) {
    const publicKey = data.public_key;

    return publicKey && publicKey !== NTR.NO_ACCESS ? publicKey : NTR.EMPTY;
  }
}
