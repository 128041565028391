import {NTR} from "../../name-to-request";

export default class TeamParsing {
  static teamId(response) {
    return response[NTR.TEAM_ID];
  }

  static teamTitle(data) {
    const teamTitle = data.team_title;

    if (!teamTitle || teamTitle === NTR.NO_ACCESS) {
      return ``;
    }

    return teamTitle.replace(/\s+/g, ` `).trim();
  }

  static teamAlias(data) {
    const alias = data.team_alias;

    if (!alias || alias === NTR.NO_ACCESS) {
      return [];
    }

    const languages = Object.keys(alias);

    return languages.map((lang) => {
      return {
        language: lang,
        title: alias[lang],
      };
    });
  }

  static teamCity(data) {
    const teamCity = data.team_city;

    if (!teamCity || teamCity === NTR.NO_ACCESS) {
      return ``;
    }

    return teamCity;
  }

  static teamGoogleCity(data) {
    const teamGoogleCity = data.team_google_city;

    if (!teamGoogleCity || teamGoogleCity === NTR.NO_ACCESS) {
      return ``;
    }

    return teamGoogleCity;
  }

  static teamCityPrint(data) {
    const teamCityPrint = data.team_city_print;

    return !!teamCityPrint && teamCityPrint !== NTR.NO_ACCESS && teamCityPrint === NTR.ONE;
  }
}
