import NominationModel from "./model";
import RequestController from "../../static/request/request";
import NominationRequest from "./request";

export default class NominationData {
  constructor({api, contestId, nominationId = ``}) {
    this._api = api;
    this._contestId = contestId;
    this._nominationId = nominationId;

    this._nomination = null;
  }

  getData(parameters) {
    return this._getData(parameters)
    .then((nomination) => this._setData(nomination))
    .then(() => this._nomination);
  }

  getChainData(parameters) {
    return this._getChainData(parameters)
    .then((nomination) => this._setData(nomination))
    .then(() => this._nomination);
  }

  getChainData2({nominationId, parameters}) {
    return this._getChainData2(nominationId, parameters)
    .then((nomination) => this._setData(nomination))
    .then(() => this._nomination);
  }

  getOneData({nominationId, parameters}) {
    return this._getOneData(nominationId, parameters);
  }

  _getOneData(nominationId, parameters) {
    return new Promise((resolve) => {
      const requestController = new RequestController({
        api: this._api,
        getRequest: NominationRequest.getOne,
        parameter: {
          // contestId: this._contestId,
          nominationId,
          parameters,
        },
        checkRequiredParameter: {nominationId},
        requiredResponseParameters: [],
        parseResponse: NominationModel.parse,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по номинации.`),
      });

      requestController.send();
    });
  }

  _getChainData(parameters) {
    return new Promise((resolve) => {
      const requestController = new RequestController({
        api: this._api,
        getRequest: NominationRequest.getOne,
        parameter: {
          // contestId: this._contestId,
          nominationId: this._nominationId,
          parameters,
        },
        checkRequiredParameter: {nominationId: this._nominationId},
        requiredResponseParameters: [],
        parseResponse: NominationModel.parseSubNomination,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по номинации.`),
      });

      requestController.send();
    });
  }

  _getChainData2(nominationId, parameters) {
    return new Promise((resolve) => {
      const requestController = new RequestController({
        api: this._api,
        getRequest: NominationRequest.getOne,
        parameter: {
          // contestId: this._contestId,
          nominationId,
          parameters,
        },
        checkRequiredParameter: {nominationId},
        requiredResponseParameters: [],
        parseResponse: NominationModel.parseSubNomination,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по номинации.`),
      });

      requestController.send();
    });
  }

  _setData(data) {
    this._nomination = data;
  }

  _getData(parameters) {
    return new Promise((resolve) => {
      const requestController = new RequestController({
        api: this._api,
        getRequest: NominationRequest.getOne,
        parameter: {
          contestId: this._contestId,
          nominationId: this._nominationId,
          parameters,
        },
        checkRequiredParameter: {nominationId: this._nominationId},
        requiredResponseParameters: [],
        parseResponse: NominationModel.parse,
        onResolveHandler: (data) => resolve(data),
        onRejectHandler: () => {},
        onRequestErrorHandler: (error) => error.showCustom(`Не удалось получить данные по номинации.`),
      });

      requestController.send();
    });
  }

  setRatingMethod(method, onResolveHandler = () => {}) {
    new RequestController({
      api: this._api,
      parameter: {nominationId: this._nominationId, method},
      checkRequiredParameter: {nominationId: this._nominationId, method},
      requiredResponseParameters: [],
      getRequest: NominationRequest.setRatingMethod,
      onResolveHandler: () => onResolveHandler(),
      onRejectHandler: () => {},
      onRequestErrorHandler: (error) => error.showCustom(`Не удалось установить метод расчета оценки для номинации.`),
    }).send();
  }
}
