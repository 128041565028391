export const getRegistratorDataPattern = (data) => {
  if (!data.registrator) {
    return {};
  }

  const phones = data.registrator.phones ? data.registrator.phones.map((item) => item.phone).join(`, `) : ``;
  const emails = data.registrator.emails ? data.registrator.emails.map((item) => item.mail).join(`, `) : ``;

  return {
    [`[[performance_add_people_surname]]`]: data.registrator.surname || ``,
    [`[[performance_add_people_lastname]]`]: data.registrator.lastname || ``,
    [`[[performance_add_people_middlename]]`]: data.registrator.middlename || ``,
    [`[[performance_add_people_telephone]]`]: phones,
    [`[[performance_add_people_email]]`]: emails,
  };
};
