import {NTR} from "../../name-to-request";
import {getDataRequest} from "../../utils/request";
import {ALL_NOMINATION_PARAMETERS} from "./parameters";

export default class NominationRequest {
  static getOne({contestId, nominationId, parameters = ALL_NOMINATION_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.CONTEST_ID]: contestId,
        [NTR.NOMINATION_ID]: nominationId,
        [NTR.SIZE]: NTR.NULL,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static setRatingMethod({nominationId, method}) {
    return {
      [NTR.SET_NOMINATION_RATING_METHOD]: {
        [NTR.NOMINATION_ID]: nominationId,
        [NTR.RATING_METHOD]: method,
      }
    };
  }
}
