import {Language, LanguageToEngText} from "../../const";
import {DefaultTitleRegistrationField} from "../../const/registration-field";

export const getTextBlocksRegistrationField = (languageData, nameField, textIndex, defaultValue) => {
  const nameValue = `${nameField}_text_block_${textIndex}`;

  if (!languageData) {
    return defaultValue || DefaultTitleRegistrationField[nameValue];
  }

  const language = languageData.language;

  if (language === LanguageToEngText[Language.RU]) {
    return languageData.getData(nameValue) || defaultValue || `${DefaultTitleRegistrationField[nameValue]}`;
  }

  return languageData.getData(nameValue) || `${DefaultTitleRegistrationField[nameValue]} (${language})`;
};

export const getTextBlocksRegistrationFieldWithComponentId = (languageData, nameField, componentId = `000000`, textIndex, defaultValue) => {
  const nameValue = `${nameField}_text_block_${textIndex}`;

  if (!languageData) {
    return defaultValue || DefaultTitleRegistrationField[nameValue];
  }

  const language = languageData.language;

  const nameWithComponentId = `${nameValue}_${componentId}`;

  if (componentId === `000000`) {
    if (language === LanguageToEngText[Language.RU]) {
      return languageData.getData(nameWithComponentId) || languageData.getData(nameValue) || defaultValue || `${DefaultTitleRegistrationField[nameValue]}`;
    }

    return languageData.getData(nameWithComponentId) || languageData.getData(nameValue) || `${DefaultTitleRegistrationField[nameValue]} (${language})`;
  } else {
    if (language === LanguageToEngText[Language.RU]) {
      return languageData.getData(nameWithComponentId) || defaultValue || `${DefaultTitleRegistrationField[nameValue]}`;
    }

    return languageData.getData(nameWithComponentId) || `${DefaultTitleRegistrationField[nameValue]} (${language})`;
  }
};
