import {NTR} from "../../name-to-request";
import {getCodeCheck} from "../../utils/common";
import {getDataRequest} from "../../utils/request";
import {ALL_ORGANIZATION_PARAMETERS} from "./parameter";

export default class OrganizationRequest {
  static getAll({parameters = ALL_ORGANIZATION_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.ORGANIZATION_ID]: NTR.ZERO,
        [NTR.SIZE]: NTR.ALL,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static getOne({organizationId, parameters = ALL_ORGANIZATION_PARAMETERS}) {
    return {
      [NTR.GET_DATA]: {
        [NTR.ORGANIZATION_ID]: organizationId,
        [NTR.SIZE]: NTR.ZERO,
        [NTR.DATA]: getDataRequest(parameters),
      }
    };
  }

  static setMailServer({organizationId, mailServer, login, password}) {
    return {
      [NTR.SET_ORGANIZATION_SMTP]: {
        [NTR.ORGANIZATION_ID]: organizationId,
        [NTR.MAIL_SERVER]: mailServer,
        [NTR.LOGIN]: login,
        [NTR.PASS]: password,
      }
    };
  }

  static title({title, organizationId}) {
    return {
      [NTR.SET_ORGANIZATION_TITLE]: {
        [NTR.ORGANIZATION_TITLE]: title,
        [NTR.ORGANIZATION_ID]: organizationId,
      }
    };
  }

  static city({organizationId, city, cityId}) {
    return {
      [NTR.SET_ORGANIZATION_CITY]: {
        [NTR.ORGANIZATION_ID]: organizationId,
        [NTR.ORGANIZATION_CITY]: city,
        [NTR.GOOGLE_CITY]: cityId,
      }
    };
  }

  static cityPrint({organizationId, isCityPrint}) {
    return {
      [NTR.SET_ORGANIZATION_CITY_PRINT]: {
        [NTR.ORGANIZATION_ID]: organizationId,
        [NTR.ORGANIZATION_CITY_PRINT]: getCodeCheck(isCityPrint),
      }
    };
  }
}
