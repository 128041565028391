import {LanguageToEngText} from "../../const";
import LanguageLangController from "../lang-setting/language";
import LanguageLangoController from "../lango-setting/language";


export default class LanguageLangAdapterController {
  constructor({api, lang, language}) {
    this._api = api;

    this._lang = lang; // краткая форма
    this._language = language;

    this._langController = new LanguageLangController({api, lang: this._lang, language: LanguageToEngText[this._lang]});
    this._langoController = new LanguageLangoController({api, lang: this._lang, language: LanguageToEngText[this._lang]});
  }

  isFull() {
    if (this._langController) {
      return this._langController.isFull();
    }

    if (this._langoController) {
      return this._langoController.isFull();
    }

    return false;
  }

  get language() {
    return this._language;
  }

  get lang() {
    return this._lang;
  }

  getData(name) {
    return this.getLang(name) || this.getLango(name);
  }

  getLang(name) {
    if (!this._langController) {
      return null;
    }

    return this._langController.getData(name);
  }

  getLango(name) {
    if (!this._langoController) {
      return null;
    }

    return this._langoController.getData(name);
  }
}
