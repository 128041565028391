import {Language, LanguageToEngText} from "../../const";
import {DefaultTitleRegistrationField} from "../../const/registration-field";

export const getPopupRegistrationField = (languageData, namePopup, field, defaultValue = ``) => {
  const nameValue = `${namePopup}_${field}`;

  if (!languageData) {
    return `${DefaultTitleRegistrationField[nameValue]}`;
  }

  const language = languageData.language;

  if (language === LanguageToEngText[Language.RU]) {
    return languageData.getData(nameValue) || defaultValue || `${DefaultTitleRegistrationField[nameValue]}`;
  }

  return languageData.getData(nameValue) || `${DefaultTitleRegistrationField[nameValue]} (${language})`;

  // return {
  //   title: popupTitle,
  //   text: contentTitle,
  // };

  // return languageData.getData(nameValue) || `${defaultValue} (${language})`;
};
