import ParsingSearchData from "../static/parsing-search/parsing-search";

export default class SearchNewModel {
  constructor(data) {
    // this.peoples = ParsingSearchData.peoples(data);
    this.performances = ParsingSearchData.performance(data);
    this.teams = ParsingSearchData.team(data);
    this.organizations = ParsingSearchData.organization(data);
  }

  static parseSearchData(data) {
    return new SearchNewModel(data);
  }
}
