
export default class ParsingSearchData {
  // static peoples(data) {

  // }

  static performance(data) {
    const performance = data.performance;

    if (!performance || performance.count === 0) {
      return [];
    }

    const keys = Object.keys(performance);

    return keys
    .filter((key) => key !== `count`)
    .map((performanceId) => {
      return {
        performanceId,
        title: performance[performanceId]
      };
    });
  }

  static team(data) {
    const team = data.team;

    if (!team || team.count === 0) {
      return [];
    }

    const keys = Object.keys(team);

    return keys
    .filter((key) => key !== `count`)
    .map((teamId) => {
      return {
        teamId,
        title: team[teamId]
      };
    });
  }

  static organization(data) {
    const organization = data.organization;

    if (!organization || organization.count === 0) {
      return [];
    }

    const keys = Object.keys(organization);

    return keys
    .filter((key) => key !== `count`)
    .map((organizationId) => {
      return {
        organizationId,
        title: organization[organizationId]
      };
    });
  }
}
