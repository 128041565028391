import {NTR} from "../../name-to-request";

export const OrganizationParameter = {
  TITLE: {[NTR.ORGANIZATION_TITLE]: NTR.EMPTY},
  CITY: {[NTR.ORGANIZATION_CITY]: NTR.EMPTY},
  CITY_ID: {[NTR.ORGANIZATION_GOOGLE_CITY]: NTR.EMPTY},
  ALIAS: {[NTR.ORGANIZATION_ALIAS]: NTR.EMPTY},
};

export const ALL_ORGANIZATION_PARAMETERS = [
  OrganizationParameter.ALIAS,
  OrganizationParameter.TITLE,
  OrganizationParameter.CITY,
  OrganizationParameter.CITY_ID,
];
