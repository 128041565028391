import {NTR} from "../../name-to-request";

export const NominationParameter = {
  DIRECTION_DOWN: {[NTR.CHAIN]: NTR.DOWN},
  DIRECTION_UP: {[NTR.CHAIN]: NTR.UP},
  SUB_ALL: {[NTR.CHAIN]: NTR.ALL},
  MAX_PLAYER: {[NTR.DOCUMENT_MAX_PEOPLE]: NTR.EMPTY},
  SUBNOMINATION: {[NTR.SUB_NOMINATION]: NTR.EMPTY},
  RATING_METHOD: {[NTR.NOMINATION_RATING_METHOD]: NTR.EMPTY},
};

export const ALL_NOMINATION_PARAMETERS = [
  NominationParameter.DIRECTION_DOWN,
];
