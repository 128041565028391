import {isEmptyArray} from "../../../utils/common";
import {getTextName} from "../../../utils/name";

export default class ContactPeoplesController {
  constructor({registrator = null, players = [], directors = [], onChangeContactPeoples, onChangePlayerEmail, onChangeDirectorEmail}) {
    this._registrator = registrator;
    this._players = players;
    this._directors = directors;

    this._contacts = [];

    this._onChangeContactPeoples = onChangeContactPeoples;
    this._onChangePlayerEmail = onChangePlayerEmail;
    this._onChangeDirectorEmail = onChangeDirectorEmail;

    this._peoples = [];
  }

  get contactPeoples() {
    return this._peoples;
  }

  init() {
    this._getPeoples();
  }

  addContact(contact) {
    this._contacts.push(contact);
    this._getPeoples();

    this._onChangeContactPeoples();
  }

  getContact(contact) {
    return this._getContact(contact);
  }

  registratorChange(registrator) {
    this._registrator = registrator;
    this._getPeoples();

    this._onChangeContactPeoples();
  }

  changePlayers(players) {
    this._players = players;
    this._getPeoples();

    this._onChangeContactPeoples();
  }

  changeDirectors(directors) {
    this._directors = directors;
    this._getPeoples();

    this._onChangeContactPeoples(this._peoples);
  }

  changeContactEmail(contact, newEmail, onResolveHandler) {
    // определить к какому типу отновится контакт
    if (this._registrator && this._registrator === contact) {
      return;
    }

    const indexPlayer = this._players.findIndex((player) => player === contact);

    if (indexPlayer >= 0) {
      this._onChangePlayerEmail(contact, newEmail, onResolveHandler);
      return;
    }

    const indexDirector = this._directors.findIndex((director) => director === contact);

    if (indexDirector >= 0) {
      this._onChangeDirectorEmail(contact, newEmail, onResolveHandler);
      return;
    }
  }

  getPeoples() {
    return [].concat(this._contacts, this._registrator, this._players, this._directors);
  }

  _getContact(people) {
    return {
      id: people.peopleId,
      text: `${getTextName(people)} (${this._getEmailContactPeople(people)})`,
    };
  }

  _getRegistrator(people) {
    return {
      id: people.peopleId,
      text: `${getTextName(people)}, регистратор (${this._getEmailContactPeople(people)})`,
    };
  }

  _getPlayer(people) {
    return {
      id: people.peopleId,
      text: `${getTextName(people)}, участник (${this._getEmailContactPeople(people)})`,
    };
  }

  _getDirector(people) {
    return {
      id: people.peopleId,
      text: `${getTextName(people)}, руководитель (${this._getEmailContactPeople(people)})`,
    };
  }

  _getPeoples2() {
    this._peoples = [].concat(this._contacts, this._registrator, this._players, this._directors);
  }

  _getPeoples() {
    const peoples = [];

    this._contacts.forEach((contact) => {
      peoples.push(this._getContact(contact));
    });

    if (this._registrator) {
      peoples.push(this._getRegistrator(this._registrator));
    }

    this._players.forEach((player) => {
      peoples.push(this._getPlayer(player));
    });

    this._directors.forEach((director) => {
      peoples.push(this._getDirector(director));
    });

    this._peoples = peoples;
  }

  isNoEmail(people) {
    return !people || !people.emails || isEmptyArray(people.emails) || !people.emails[0] || !people.emails[0].mail;
  }

  _getEmailContactPeople(people) {
    if (this.isNoEmail(people)) {
      return `email не указан`;
    }

    return people.emails[0].mail;
  }
}
